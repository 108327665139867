import i18n from '../../../../../i18n';

export const columns = [
  {
    key: 'quantity',
    label: i18n.t('Quantity'),
    sizes: {
      xs: '4'
    }
  },
  {
    key: 'cost_per_unit',
    label: i18n.t('Cost/unit'),
    sizes: {
      xs: '4'
    }
  },
  {
    key: 'unit',
    label: i18n.t('Unit'),
    sizes: {
      xs: '4'
    }
  },
  {
    key: 'total',
    alignment: 'start',
    label: i18n.t('Total'),
    sizes: {
      xs: '4'
    }
  },
];
