import { Cell, Label, Pie, PieChart, ResponsiveContainer } from 'recharts';

import styles from './styles/RadialChart.module.scss';
const calculateTotal = (data: { value: number }[]) => {
  return data.reduce((acc, entry) => acc + entry.value, 0);
};

const calculatePercentage = (data: { value: number }[], index: number) => {
  const total = calculateTotal(data);
  if (total === 0 && data[index].value === 0) return null;
  return total ? Math.round((data[index].value / total) * 100) : 0;
};

const RadialChart: React.FC<RadialChartProps> = ({ data, label, percentageIndex, size = '100%' }) => {
  const percentage = calculatePercentage(data, percentageIndex);
  const labelFill = label?.fill || '#44444f';
  const fontSize = label?.fontSize || 30;
  const innerLabel = percentage === null ? 'N/A' : `${percentage}%`;

  return (
    <ResponsiveContainer width={size} height={200} style={{ display: 'flex', justifyContent: 'center' }}>
      <PieChart style={{ width: size, }}>
        <defs>
          <linearGradient id="gradient1" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" style={{ stopColor: '#f36f10', stopOpacity: 1 }} />
            <stop offset="100%" style={{ stopColor: '#fd9951', stopOpacity: 1 }} />
          </linearGradient>
          <linearGradient id="gradient2" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" style={{ stopColor: '#1adc62', stopOpacity: 1 }} />
            <stop offset="100%" style={{ stopColor: '#298f53', stopOpacity: 1 }} />
          </linearGradient>
        </defs>

        <Pie
          data={percentage === null ? [{ value: 1 }] : data}
          dataKey="value"
          startAngle={90}
          endAngle={-270}
          innerRadius="70%"
          outerRadius="80%"
          stroke="none"
          className={styles['pie-wrapper__remove-outline']}
        >
          <Label
            value={innerLabel}
            position="center"
            dy={percentage === null ? 0 : -4}
            dx={percentage === null ? 0 : 4}
            fill={labelFill}
            fontSize={fontSize}
            fontWeight="bold"
          />

          {percentage === null
            ? <Cell
              fill={'var(--ion-color-light-shade)'}
              className={styles['cell__remove-outline']} />
            : (<>
              <Label
                className={styles['label__remove-outline']}
                value={'Secured'}
                position='centerBottom'
                fill={labelFill}
                dy={fontSize}
                fontSize={fontSize / 2}
                fontWeight="normal"
              />
              {data.map((_, index: any) => (
                <Cell
                  className={styles['cell__remove-outline']}
                  key={`cell-${index}`}
                  fill={index % 2 === 0 ? 'url(#gradient1)' : 'url(#gradient2)'}
                />
              ))}
            </>)
          }
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default RadialChart;
