import { IonContent, IonItem, IonList, useIonRouter } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import React from 'react';
import { useHistory } from 'react-router';

import useAcl from '../../../hooks/useAcl';
import { setSelectedProject } from '../../../reducers/project';
import store from '../../../store';

interface ProjectSelectionPopoverProps {
  nextPath?: string;
  onNavigate?: (to: string) => void;
}
export const ProjectSelectionPopover: React.FC<ProjectSelectionPopoverProps> = ({ nextPath, onNavigate }) => {
  const { getProjects } = useAcl();
  const projects = getProjects();

  const handleProjectSelection = (project: E2U.V1.Models.Project) => {
    store.dispatch(setSelectedProject(project));
    if (nextPath && onNavigate) {
      onNavigate(nextPath.replace(/:uuid\??/, project?.id ?? ''));
    }
  };

  return (
    <IonContent scrollY>
      <IonList>
        {projects.map((project, i) => (
          <IonItem
            key={i}
            button={true}
            detail={false}
            onClick={() => handleProjectSelection(project)}
          >
            {project.name}
          </IonItem>
        ))}
      </IonList>
    </IonContent>
  );
};
