import React from 'react';

import DocumentsListTable from '../../Document/DocumentsListTable';

const ProjectDocuments: React.FC = () => {
  return (
    <React.Fragment>
      <div className='ion-padding'>
        <DocumentsListTable />
      </div>
    </React.Fragment>
  );
};

export default ProjectDocuments;
