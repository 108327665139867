import type { E2U } from '@techlove/easy2use-typings';
import { checkmarkOutline, closeOutline } from 'ionicons/icons';

import { customBorder } from '../../../components/UI/variables';
import i18n from '../../../i18n';

const mappings = (record: E2U.V1.Models.ControlOfExecution) => {
  const handleIconList = new Map([
    [record.requires_supervisor && !record.has_been_attested, { icon: closeOutline, message: i18n.t('Supervision required') }],
    [record.has_been_attested && !record.requires_supervisor, { icon: checkmarkOutline, message: i18n.t('Attested') }],
    [!record.has_been_attested && !record.requires_supervisor, { icon: '', message: i18n.t('Unattested') }]
  ]);
  const statusColour = new Map([
    [record.requires_supervisor && !record.has_been_attested, 'danger'],
    [record.has_been_attested && !record.requires_supervisor, 'success'],
    [!record.has_been_attested && !record.requires_supervisor, 'medium']
  ]);
  const borderLeftColour = new Map([
    [record.requires_supervisor, customBorder.borderCoulorRed],
    [record.has_been_attested, customBorder.borderColourGreen],
    [!record.requires_supervisor && !record.has_been_attested, customBorder.borderColourGray]
  ]);
  return { handleIconList, statusColour, borderLeftColour };
};

export default mappings;
