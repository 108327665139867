import {
  IonBadge,
  IonChip,
  IonCol,
  IonGrid,
  IonIcon,
  IonList,
  IonRow,
  IonSelectOption,
  useIonAlert,
  useIonPopover,
  useIonRouter
} from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { close, eyeOutline, filterOutline } from 'ionicons/icons';
import { filter } from 'lodash';
import type { MouseEventHandler } from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import AddNewButton from './AddNewButton';
import { levelColour } from './config';
import type { NoticeFormValues } from './Form/form.interface';
import useNoticeBoardList from './useNoticeBoardList';
import DesktopWrapper from '../../components/DesktopWrapper';
import ProjectSelector from '../../components/ProjectSelector';
import BigUp from '../../components/UI';
import EmptyList from '../../components/UI/EmptyList';
import SortByDropdown from '../../components/UI/SearchAndSort/Sort/SortByDropdown';
import { useAppSelector } from '../../hooks';
import usePermissionHandler from '../../hooks/usePermissions';
import i18n from '../../i18n';
import { formatToYYYYMMDD } from '../../tools/formatDates';

const NoticeBoardContent: React.FC = () => {
  const { uuid } = useParams<{ uuid: string | undefined }>();
  const project: E2U.V1.Models.Project | undefined = useAppSelector((state) => state.project.selectedProject);
  const selectedProjectId = useMemo(() => project?.id, [project]);
  const isDesktop = useAppSelector(state => state.desktopView.isDesktop);
  const router = useIonRouter();
  const { t } = useTranslation();
  const [presentAlert] = useIonAlert();
  const {
    filterInputs,
    filterValues,
    getProjectNotices,
    notices,
    projectNotices,
    setFilterValues,
    setSortNoticesBy,
    setVisibilityValues,
    toggleVisibility,
    visibilityInputs,
    visibilityValues,
  } = useNoticeBoardList();

  const [present] = useIonPopover(BigUp.Popovers.ProjectSelectionPopover, {
    nextPath: `/notice-board/:uuid/add`,
    onNavigate: (to: string) => {
      router.push(to);
    },
  });

  useEffect(() => {
    if (project?.id) {
      getProjectNotices();
    }
  }, [project?.id]);

  const presentFilterByLevelAlert = () => {
    presentAlert({
      header: i18n.t('Filter by level'),
      inputs: filterInputs as any,
      buttons: [{
        text: i18n.t('Cancel'), role: 'cancel'
      },
      {
        text: i18n.t('Ok'), handler: (value) => setFilterValues(value)
      }],
    });
  };

  const presentFilterByVisibilityAlert = () => {
    presentAlert({
      header: i18n.t('Filter by visibility'),
      inputs: visibilityInputs as any,
      buttons: [{
        text: i18n.t('Cancel'), role: 'cancel'
      },
      {
        text: i18n.t('Ok'),
        handler: (value) => setVisibilityValues(value)
      }],
    });
  };

  const noticeList = (type: NoticeFormValues[] | undefined) => {
    return (
      type?.map((item, i) => {
        return (
          <BigUp.MetadataItem
            key={i}
            indicator={{
              hasIndicator: false,
              color: levelColour.get(item.level)
            }}
            ionTextProps={{
              color: 'medium',
              IonTextProps: { children: formatToYYYYMMDD(item.publish_at) ?? '' }
            }}
            onItemClick={() => router.push(uuid ? `/notice-board/${project?.id}/notice/${item.id}` : `/notice-board/notice/${item.id}`)}
            toprightContent={<IonBadge style={{ background: levelColour.get(item.level) }}>
              {filterInputs.find(fV => fV.value === item.level)?.label}
            </IonBadge>}
            subject={item.subject}
            message={item.message}
          />
        );
      })
    );
  };

  const handleCreateNotice: MouseEventHandler = (e) => {
    if (selectedProjectId) {
      router.push(`/notice-board/${selectedProjectId}/add`);
    } else {
      present({
        event: e as any,
        dismissOnSelect: true,
      });
    }
  };

  return (
    <DesktopWrapper width='var(--ion-desktop-mid-width)' alignment={'center'} paddedPage={false}>
      <IonGrid>
        <IonRow className='ion-align-items-center ion-no-padding'>
          <IonCol size='12' sizeXs='7'>
            <h3>
              {
                selectedProjectId
                  ? i18n.t('Notices for {project}', 'Notices for {project}', { project: project?.name })
                  : i18n.t('Notices')
              }
            </h3>
          </IonCol>
          <IonCol size='12' sizeXs='5' className='ion-text-right'>
            <AddNewButton />
          </IonCol>
        </IonRow>
        <IonRow className='ion-align-items-between'>
          <IonCol size='12'>
            {isDesktop && <ProjectSelector currentTab={'notice-board'} color='var(--ion-color-dark)' />}
          </IonCol>
        </IonRow>
        <IonRow className='ion-align-items-center'>
          <IonCol size='7'>
            <IonList>
              <SortByDropdown
                onSort={(value: 'publish_at' | 'expire_at' | string | any) => {
                  setSortNoticesBy({
                    date: value,
                    direction: value === 'publish_at' ? 'desc' : 'asc'
                  });
                  getProjectNotices();
                }}>
                <IonSelectOption value='publish_at'>{i18n.t('Latest')}</IonSelectOption>
                <IonSelectOption value='expire_at'>{i18n.t('Oldest')}</IonSelectOption>
              </SortByDropdown>
            </IonList>
          </IonCol>
          <IonCol size='5' className='ion-text-right ion-padding-top'>
            <IonRow className='ion-align-items-center ion-justify-content-end'>
              <IonCol size={'auto'}>
                <BigUp.Buttons.Icon
                  mode='ios'
                  color={'none'}
                  icon={{ icon: eyeOutline, color: 'dark', style: { fontSize: '30px' } }}
                  onClick={presentFilterByVisibilityAlert}
                />
              </IonCol>
              <IonCol size={'auto'}>
                <BigUp.Buttons.Icon
                  mode='ios'
                  color={'none'}
                  icon={{ icon: filterOutline, color: 'dark', style: { fontSize: '30px' } }}
                  onClick={presentFilterByLevelAlert}
                />
              </IonCol>
            </IonRow>
          </IonCol>
        </IonRow>
      </IonGrid>
      <section className='ion-padding-start'>
        {filterValues.map((value, i) => {
          return (
            <IonChip
              itemType='button'
              key={i}
              color={'dark'}
              onClick={() => setFilterValues(filter(filterValues, (item) => item !== value))}
            >
              <IonIcon icon={filterOutline} size='small' />{filterInputs.find(fV => fV.value === value)?.label} <IonIcon icon={close} />
            </IonChip>
          );
        })}
      </section>
      <section className='ion-padding-start'>
        {visibilityValues.map((value, i) => {
          return (
            <IonChip
              key={i}
              color={'dark'}
              onClick={() => toggleVisibility(value)}
            >
              <IonIcon icon={eyeOutline} size='small' />
              {visibilityInputs.find(vV => vV.value === value)?.label}
              <IonIcon icon={close} />
            </IonChip>
          );
        })}
      </section>
      <IonList className='ion-padding-horizontal'>
        {uuid
          ? (noticeList(projectNotices))
          : (noticeList(notices))
        }
      </IonList>
      {projectNotices?.length === 0 &&
          <EmptyList
            title={i18n.t('No notices has been created for {project}', 'No notices has been created for {project}', { project: project?.name })}
            message={<>{t('Click')} <span onClick={(e) => handleCreateNotice(e)}><u>{t('here')}</u></span> {t('to create one.')}</>}
          />
      }
    </DesktopWrapper>
  );
};

export default NoticeBoardContent;
