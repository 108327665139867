import {
  IonContent,
  IonGrid,
  IonItem,
  IonLabel,
  IonMenu,
  IonRow
} from '@ionic/react';
import classNames from 'classnames';
import { lockClosed } from 'ionicons/icons';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import BigUp from '..';
import styles from './SideMenu.module.scss';
import { useAppSelector } from '../../../hooks';
import useAcl from '../../../hooks/useAcl';
import type { ColourVariables } from '../variables';
import type { BaseUrlOptions } from './SideMenuLayout';
import Panel from '../../Panels/Panel';

interface SideMenuOptionsProps {
  uuid: string;
  menuItems: any[];
  label: string;
  baseUrl: BaseUrlOptions;
  toolColour: ColourVariables;
}

const SideMenuOptions: React.FC<SideMenuOptionsProps> = ({
  baseUrl,
  menuItems,
  toolColour,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { clientAbilities, getProjects } = useAcl();
  const selectedProject = useAppSelector((state) => state.project.selectedProject);
  const selectedProjectId = useMemo(() => selectedProject?.id, [selectedProject]);

  const handleProjectClick = (projectId: string | undefined) => {
    history.replace(`/${baseUrl}/${projectId}/${menuItems[0].value}`);
  };

  return (
    <IonMenu className={styles['split-pane-wrapper']} contentId="main">
      <IonContent className="ion-padding" color={'light'}>
        {clientAbilities.includes('can_create_projects') && (
          <IonGrid>
            <IonRow className='ion-justify-content-evenly'>
              <BigUp.Buttons.Regular
                color={'primary'}
                title={t('Create a project')}
                size='small'
                shape='round'
                onClick={() => history.push('/tools/create-project')}
              />
            </IonRow>
          </IonGrid>
        )}
        <div className="ion-padding">
          {getProjects().map((project) => (
            <React.Fragment key={project.id}>
              <IonItem
                className={classNames(styles['project-item'], {
                  [styles['selected-project']]: project.id === selectedProjectId,
                })}
                onClick={() => handleProjectClick(project.id)}
              >
                <IonLabel style={{
                  color: project.id === selectedProjectId ? toolColour : 'var(--ion-color-medium)',
                  fontWeight: project.id === selectedProjectId ? 800 : 400,
                }}>
                  {project.name}
                </IonLabel>
              </IonItem>
              {project.id === selectedProjectId && (
                <>
                  <div style={{ padding: 20, background: 'var(--ion-color-light)' }}>
                    {menuItems.map((item) => (
                      <Panel
                        key={item.value}
                        onClick={() => !item.subpanelDisabled && history.push(`/${baseUrl}/${item.route}`)}
                        panelTitle={item.subpanelTitle}
                        icon={{
                          start: {
                            icon: item.subpanelDisabled ? lockClosed : item.subpanelIcon,
                            color: item.subpanelDisabled ? 'medium' : 'dark'
                          },
                        }}
                        disabled={item.subpanelDisabled}
                      />
                    ))}
                  </div>
                </>
              )}
            </React.Fragment>
          ))}
        </div>
      </IonContent>
    </IonMenu>
  );
};

export default SideMenuOptions;
