import type { ParsedUrlQueryInput } from 'querystring';
import { encode } from 'querystring';

import { IonCol, IonGrid, IonLabel, IonRow, IonSegment, IonSegmentButton, IonSelectOption } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { settingsOutline } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import AccessRequestCard from './AccessRequestCard';
import styles from './SiteAccessRequests.module.scss';
import { networking } from '../../../api/networking';
import PaginateData from '../../../components/Pagination/PaginationData';
import SkeletonTextThreeLines from '../../../components/SkeletonComponents/SkeletonTextThreeLines';
import toasters from '../../../components/Toasts/Toasts';
import BigUp from '../../../components/UI';
import { handleEmptyListStates } from '../../../components/UI/EmptyList';
import SearchAndSortRow from '../../../components/UI/SearchAndSort/SearchAndSortRow';
import SiteAccessRequestStatus from '../../../constants/enums/SiteAccessRequestStatus';
import { emptyListMessages } from '../../../constants/messages';
import { useAppSelector } from '../../../hooks';
import { setIsLoading } from '../../../reducers/loading';
import store from '../../../store';
import { scrollToSection } from '../../../tools/scrollToSection';

const SiteAccessRequests: React.FC = () => {
  const { t } = useTranslation();
  const [siteRequests, setSiteRequests] = useState<E2U.V1.Models.ProjectAccessRequest[]>([]);
  const [currentTab, setCurrentTab] = useState<SiteAccessRequestStatus>(SiteAccessRequestStatus.PENDING);
  const [tabCounts, setTabCounts] = useState<Omit<{ [key in SiteAccessRequestStatus]: number }, 'unsubmitted'>>({
    pending: 0,
    rejected: 0,
    approved: 0
  });
  const [search, setSearch] = useState<string>('');
  const [sortBy, setSortBy] = useState<string>('created_at');
  const [totalPages, setTotalPages] = useState<number>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { uuid } = useParams<{ uuid: string }>();
  const history = useHistory();

  const project = useAppSelector(state => state.project.selectedProject);
  const isDesktop = useAppSelector((state) => state.desktopView.isDesktop);
  const isLoadingRequests: boolean = useAppSelector(state => state.loading.isLoading.requests);

  const updateTabCounts = async () => {
    try {
      setTabCounts(
        {
          pending: await fetchSiteAccessRequestStatusCount(SiteAccessRequestStatus.PENDING),
          rejected: await fetchSiteAccessRequestStatusCount(SiteAccessRequestStatus.REJECTED),
          approved: await fetchSiteAccessRequestStatusCount(SiteAccessRequestStatus.APPROVED),
        }
      );
    } catch (err) {
      console.warn(err);
      toasters.error(t('Failed fetching projects access requests...'));
    }
  };
  const handleStatusUpdate = () => {
    fetchSiteAccessRequests();
    updateTabCounts();
  };

  const fetchSiteAccessRequestStatusCount = async (status: SiteAccessRequestStatus) => {
    const filters: ParsedUrlQueryInput = {
      filters: JSON.stringify([{
        field: 'status',
        operator: '=',
        value: status
      }]),
      search
    };

    const response = await networking.get<{ data: number }>(
      `/api/v1/projects/${uuid}/access_requests/count?${encode(filters)}`
    );

    return response.data.data;
  };

  const fetchSiteAccessRequests = () => {
    store.dispatch(setIsLoading({ name: 'requests', value: true }));

    const filters: ParsedUrlQueryInput = {
      sortBy,
      filters: JSON.stringify([{
        field: 'status',
        operator: '=',
        value: currentTab
      }]),
      search
    };

    networking
      .get(`/api/v1/projects/${project?.id}/access_requests?${encode(filters)}&sort_by=${sortBy}&page=${currentPage.toString()}&per_page=10`)
      .then((response: E2U.V1.Response.Success<
        E2U.V1.Objects.PaginatedData<E2U.V1.Models.ProjectAccessRequest>>) => {
        setSiteRequests(response.data.data.records);
        setTotalPages(response.data.data.total_pages);
        setCurrentPage(response.data.data.current_page);
      })
      .catch((error: E2U.V1.Response.Error) => {
        Sentry.captureException(error);
      })
      .finally(() => {
        store.dispatch(setIsLoading({ name: 'requests', value: false }));
      });
  };
  const scrollToId = 'site-access-requests-top';

  const pageStepper = (step: number) => {
    setCurrentPage(currentPage + step);
    scrollToSection(scrollToId);
  };

  const handleEmptyTabType = () => {
    return currentTab === SiteAccessRequestStatus.PENDING
      ? t('pending')
      : currentTab === SiteAccessRequestStatus.APPROVED
        ? t('approved')
        : t('rejected');
  };
  const messages = {
    empty: {
      title: emptyListMessages.site_access_requests.empty_title(handleEmptyTabType().toLowerCase()),
      message: emptyListMessages.site_access_requests.empty_message(handleEmptyTabType().toLowerCase()),
    },
    error: {
      message: '',
    },
  };

  useEffect(() => {
    fetchSiteAccessRequests();
  }, [currentPage]);

  useEffect(() => {
    fetchSiteAccessRequests();
    updateTabCounts();
  }, [currentTab, search, sortBy]);

  return (
    <React.Fragment>
      <div className="ion-padding-horizontal">
        <div style={{ width: '100%' }}>
          <IonGrid className="ion-margin-bottom ion-padding-top">
            <IonRow>
              <IonCol>
                <BigUp.Title label={t('Site access requests')} />
              </IonCol>
            </IonRow>
            <IonRow className='ion-align-items-center'>
              <IonCol size='11'>
                <SearchAndSortRow
                  onSearch={setSearch}
                  onSort={setSortBy}
                  value={search}
                >
                  <IonSelectOption value={'created_at'}>{t('Created at')}</IonSelectOption>
                  <IonSelectOption value={'updated_at'}>{t('Last modified')}</IonSelectOption>
                </SearchAndSortRow>
              </IonCol>
              <IonCol size='1' className='ion-text-end ion-no-padding'>

                <BigUp.Buttons.Icon
                  onClick={() => history.push(`/tools/${uuid}/site-access-requests/settings`)}
                  className='ion-no-padding'
                  fill='clear'
                  color={'transparent'}
                  padding={20}
                  size='small'
                  icon={{
                    icon: settingsOutline,
                    size: 'large',
                    color: 'medium',
                  }}
                />
              </IonCol>
            </IonRow>
          </IonGrid>

          <IonSegment value={currentTab} onIonChange={(event) => setCurrentTab(event.detail.value as SiteAccessRequestStatus)}
            {...isDesktop && {
              color: currentTab === SiteAccessRequestStatus.PENDING
                ? 'secondary'
                : currentTab === SiteAccessRequestStatus.APPROVED
                  ? 'success'
                  : 'danger'
            }}>
            <IonSegmentButton value={SiteAccessRequestStatus.PENDING} >
              <IonLabel className='ion-no-margin'>{t('Pending')}</IonLabel>
              <IonLabel className='ion-no-margin'>({tabCounts.pending})</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value={SiteAccessRequestStatus.APPROVED}>
              <IonLabel className='ion-no-margin'>{t('Approved')}</IonLabel>
              <IonLabel className='ion-no-margin'>({tabCounts.approved})</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value={SiteAccessRequestStatus.REJECTED}>
              <IonLabel className='ion-no-margin'>{t('Rejected')}</IonLabel>
              <IonLabel className='ion-no-margin'>({tabCounts.rejected})</IonLabel>
            </IonSegmentButton>
          </IonSegment>

          <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', gap: 10, marginTop: 30 }}>
            {handleEmptyListStates({ isEmpty: !siteRequests.length, error: '', messages, search })}
            {isLoadingRequests
              ? <SkeletonTextThreeLines />
              : (
                siteRequests.map((request, i) => {
                  return (
                    <div key={i} className={styles['site-access-requests-wrapper']} style={{ animationDelay: `${50 * i}ms`, width: '100%', maxWidth: 400 }}>
                      <AccessRequestCard request={request} handleStatusUpdate={handleStatusUpdate} />
                    </div>
                  );
                })
              )
            }
          </div>

          <div className='ion-padding'>
            {siteRequests.length > 0 &&
              <PaginateData
                currentPage={currentPage}
                totalPages={totalPages}
                pageStepper={pageStepper}
              />
            }
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SiteAccessRequests;
