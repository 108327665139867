import { IonCol, IonRow } from '@ionic/react';
import { arrowForward } from 'ionicons/icons';
import { debounce } from 'lodash';
import React, { useState } from 'react';
import type { FieldValues, SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { FORM_VALUES, validatePersonNumber } from './formlist';
import StepButtons from '../../../../../../components/Toolbar/StepButtons';
import { BigUp } from '../../../../../../components/UI';
import { useAppSelector } from '../../../../../../hooks';
import i18n from '../../../../../../i18n';
import { setOnboardingStageOne } from '../../../../../../reducers/onboarding';
import store from '../../../../../../store';
import type { OnboardingInterface } from '../../../../interfaces/Onboarding.interface';
import OnboardingHeader from '../../../header/FormHeaderTitle';
import styles from '../../styles/OnboardingStageOne.module.scss';

const OnboardingStageOne: React.FC<OnboardingInterface> = (props) => {
  const { t } = useTranslation();
  const [checkIfSSN, setCheckIfSSN] = useState({
    value: false,
    message: '',
  });
  const [, forceUpdate] = useState(false);
  const onboarding = useAppSelector(store => store.onboarding.onboardingStageOne);
  const user = useAppSelector(store => store.authentication.user);
  const history = useHistory();
  const nextStep = () => {
    history.push('/site-access/certificates');
  };

  const methods = useForm<FieldValues>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: {
      personal_number: onboarding.personal_number,
      first_name: onboarding.first_name || user?.first_name || '',
      last_name: onboarding.last_name || user?.last_name || '',
      company_name: onboarding.company_name,
      organization_number: onboarding.organization_number,
    }
  });

  const submitForm: SubmitHandler<FieldValues> = (data: FieldValues) => {
    store.dispatch(setOnboardingStageOne(data));
  };

  const checkIfSSNIsEntered = () => {
    const personalNumber = methods.getValues('personal_number') as string;
    const isValid = validatePersonNumber(personalNumber);
    const generateAppropriateMessage = () => {
      if (isValid || !methods.formState.errors) {
        return '';
      }
      return t('The personal number does not match a Swedish personal number, make sure this is correct');
    };

    setCheckIfSSN((prevState) => ({
      ...prevState,
      value: !isValid,
      message: generateAppropriateMessage(),
    }));
    forceUpdate(true);
  };

  return (
    <React.Fragment>
      <OnboardingHeader title={i18n.t('Personal and contact information')} />
      <div className={styles['onboarding-stage-one']} >
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(submitForm)}>
            {FORM_VALUES.map((input, i) => {
              return (
                <React.Fragment key={i}>
                  <IonRow>
                    <IonCol className='ion-text-start'>
                      <BigUp.Input
                        onIonInput={() => debounce(checkIfSSNIsEntered, 300)()}
                        labelPlacement='stacked' {...input}
                        helperText={(checkIfSSN.value && input.name === 'personal_number')
                          ? checkIfSSN.message
                          : ''
                        }
                      />
                    </IonCol>
                  </IonRow>
                </React.Fragment>
              );
            })}
            <StepButtons
              leftTitle={i18n.t('cancel')}
              leftIonColor={'none'}
              leftOnClick={() => history.replace('/')}
              rightTitle={i18n.t('next')}
              rightIonColor={'secondary'}
              rightDisabled={
                !methods.formState.isValid
              }
              rightOnClick={() => nextStep()}
              rightIcon={arrowForward}
              rightSubmit={'submit'}
            />
          </form>
        </FormProvider>
      </div>

    </React.Fragment>

  );
};

export default OnboardingStageOne;
