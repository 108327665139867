import {
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonMenu,
  IonRippleEffect,
  IonRow,
  IonText, useIonRouter
} from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import classNames from 'classnames';
import { closeCircle } from 'ionicons/icons';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath } from 'react-router';
import { useHistory, useLocation } from 'react-router-dom';

import LockSubmenu from './LockSubmenu';
import { useAppSelector } from '../../../../../hooks';
import useAcl from '../../../../../hooks/useAcl';
import usePermissionHandler from '../../../../../hooks/usePermissions';
import { generateNameColor } from '../../../../../tools/generateColor';
import BigUp from '../../../index';
import styles from '../SideMenuV2.module.scss';

interface SideMenuV2SubmenuProps {
  submenuVisible?: boolean
}

const ToolsSubmenu: React.FC<SideMenuV2SubmenuProps> = (props) => {
  const location = useLocation();
  const router = useIonRouter();
  const selectedProject = useAppSelector((state) => state.project.selectedProject);
  const selectedProjectId = useMemo(() => selectedProject?.id, [selectedProject]);
  const { getProjects } = useAcl();
  const projects = getProjects();

  const checkRoutePath = (id: string) => {
    if (selectedProjectId && location.pathname.includes(selectedProjectId)) {
      return location.pathname.replace(selectedProjectId, id);
    }
    return '/tools/' + id;
  };

  const projectsWithRoute: (E2U.V1.Models.Project & {
    active: boolean;
    route: string;
    fallback_color: string;
  })[] = useMemo(() => projects.map((project) => ({
    ...project,
    route: checkRoutePath(project?.id ?? ''),
    active: selectedProjectId === project.id,
    fallback_color: generateNameColor(project?.id ?? ''),
  })), [projects, selectedProjectId]);

  return (
    <>
      {projectsWithRoute.map((project, i) =>
        <IonRow
          key={i}
          className={classNames('ion-no-margin', styles['side-menu--sub--row'])}
          onClick={() => router.push(project.route)}
        >
          <IonCol
            className={
              classNames(
                'ion-activatable ripple-parent',
                styles['side-menu--sub--item'],
                project.active
                  ? styles['side-menu--sub--item--active']
                  : ''
              )
            }
            role={'button'}
          >
            <IonRow className={'ion-align-items-center'}>
              <IonCol size={'auto'}>
                <div
                  className={
                    classNames(styles['side-menu--sub--item--avatar-fallback'])
                  }
                  style={{ backgroundColor: project.fallback_color }}
                />
              </IonCol>
              <IonCol
                className={classNames(
                  'ion-no-padding',
                  styles['side-menu--sub--item--title-holder']
                )}
              >
                <IonText className={styles['side-menu--sub--item--title']}>
                  <p className={'ion-no-margin'}>{project.name}</p>
                </IonText>
              </IonCol>
            </IonRow>
            <IonRippleEffect />
          </IonCol>
        </IonRow>
      )}
    </>
  );
};

export default ToolsSubmenu;
