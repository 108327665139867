import { IonCol, IonGrid, IonIcon, IonLabel, IonRow } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import type { CancelTokenSource } from 'axios';
import axios from 'axios';
import classNames from 'classnames';
import { chevronDown, chevronUp } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import styles from './PrecalculationRows.module.scss';
import { networking } from '../../../../api/networking';
import FadeInContainer from '../../../../components/Animated/FadeInContainer';
import PaginateData from '../../../../components/Pagination/PaginationData';
import toasters from '../../../../components/Toasts/Toasts';
import BigUp from '../../../../components/UI';
import { handleEmptyListStates } from '../../../../components/UI/EmptyList';
import { emptyListMessages } from '../../../../constants/messages';
import { useAppSelector } from '../../../../hooks';
import i18n from '../../../../i18n';
import { setIsLoading } from '../../../../reducers/loading';
import store from '../../../../store';
import formatNumber from '../../../../tools/formatNumber';
import { buildQuery } from '../../../../tools/queryBuilder';
import { scrollToSection } from '../../../../tools/scrollToSection';
import ActivityCodeTile from '../ActivityCodeTile';

interface PaginationLabelInterface {
  currentPage: number;
  filteredPagination?: number | undefined;
  totalPages: number | undefined;
}

const messages = {
  empty: {
    title: emptyListMessages.precalculation_rows.empty_title,
    message: emptyListMessages.precalculation_rows.empty_message
  },
  error: {
    message: ''
  }
};

export const PaginationLabel: React.FC<PaginationLabelInterface> = ({ currentPage, filteredPagination, totalPages }) => {
  const { t } = useTranslation();
  return (
    <IonLabel style={{ margin: '0', textAlign: 'left' }}>
      {t('Page')} {currentPage === 0 ? 1 : currentPage} {t('of')}{' '}
      {filteredPagination !== undefined && filteredPagination !== 0 ? filteredPagination : totalPages}
    </IonLabel>
  );
};

interface RowProps {
  onRowClick: (row: E2U.V1.Models.PrecalculationRow) => void;
  search: string;
  sortBy: string;
  filterParam: string | null;
  selectedPrecalculation: E2U.V1.Models.Precalculation & { precalculation_id?: string } | undefined;
  shouldRefetchRows: boolean;
  setShouldRefetchRows: (value: boolean) => void;
  type: 'precalculations' | 'precalculation_histories';
}

const PrecalculationRows: React.FC<RowProps> = (props) => {
  const { precalculationId } = useParams<{ precalculationId: string | undefined }>();
  const [precalcalculationRows, setPrecalculationRows] = useState<E2U.V1.Models.PrecalculationRow[]>([]);
  const [totalPages, setTotalPages] = useState<number>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [showFullDescription, setShowFullDescription] = useState<number | null>(null);
  const [error, setError] = useState<string | undefined>();
  const { t } = useTranslation();
  const isLoadingPrecalculation = useAppSelector((state) => state.loading.isLoading.precalculation);
  const isDesktop = useAppSelector((state) => state.desktopView.isDesktop);
  const scrollToId = 'precalculations-rows-top';
  const [cancelToken, setCancelToken] = useState<CancelTokenSource | null>(null);

  const toggleDescription = (i: number) => {
    setShowFullDescription(prev => (prev === i ? null : i));
  };

  const pageStepper = (step: number) => {
    setCurrentPage(currentPage + step);
    scrollToSection(scrollToId);
  };

  const fetchPrecalculationRow = (page?: number, id?: string) => {
    if (!props.selectedPrecalculation?.id) {
      return;
    }
    store.dispatch(setIsLoading({ name: 'precalculation', value: true }));

    props.setShouldRefetchRows(props.shouldRefetchRows);

    if (typeof page !== 'undefined') {
      setCurrentPage(page);
    }

    let filterDirection = 'asc';
    let filterSortBy: string = props.sortBy;

    if (filterSortBy.endsWith('_desc')) {
      /**
       * Ideally, this would be separate parameter, but that would almost certainly
       * require UI changes (to allow for separate sort by and direction).
       */

      filterDirection = 'desc';
      filterSortBy = filterSortBy.replace('_desc', '');
    }

    const query: string = buildQuery(
      props.search,
      filterSortBy,
      filterDirection,
      (typeof page !== 'undefined' ? page : currentPage),
      10
    );

    if (cancelToken) {
      cancelToken.cancel('Canceling previous request');
    }

    const source = axios.CancelToken.source();
    setCancelToken(source);
    const searchParams = new URLSearchParams(query);
    searchParams.append('filters', `[{"field":"secured_cost","value":${props.filterParam}}]`);
    const networkPromise = new Promise((resolve, reject) => networking.get(
      `api/v1/${props.type}/${props.selectedPrecalculation?.id}/rows?${searchParams}`,
      {
        cancelToken: source.token
      }
    ).catch(err => !axios.isCancel(err) && reject(err)).then(resolve));
    toasters.promise(
      networkPromise, {
        error: i18n.t('Failed to load precalculation rows')
      })
      .then((response: E2U.V1.Response.Success<E2U.V1.Objects.PaginatedData<E2U.V1.Models.PrecalculationRow>>) => {
        setPrecalculationRows(response.data.data.records);
        setTotalPages(response.data.data.total_pages);
        setCurrentPage(response.data.data.current_page);
      })
      .catch((error: E2U.V1.Response.Error) => {
        Sentry.captureException(error);
      })
      .finally(() => {
        store.dispatch(setIsLoading({ name: 'precalculation', value: false }));
      });
  };

  useEffect(() => {
    if (props.selectedPrecalculation?.id) {
      fetchPrecalculationRow(1);
    } else {
      setPrecalculationRows([]);
    }
  }, []);

  useEffect(() => {
    if (props.selectedPrecalculation?.id) {
      fetchPrecalculationRow(currentPage);
    } else {
      setPrecalculationRows([]);
    }
  }, [
    props.search,
    props.sortBy,
    props.filterParam,
    props.selectedPrecalculation?.id,
    currentPage,
    props.selectedPrecalculation?.rows,
    props.shouldRefetchRows
  ]);

  return (
    <React.Fragment>
      <div id={scrollToId}>
        <PaginationLabel currentPage={currentPage} totalPages={totalPages} />
      </div>

      <div className={styles['precalculations-container']} slot="content">
        <div className={styles['precalculations-row-container']}>
          {precalcalculationRows && precalcalculationRows?.length > 0
            ? (
              precalcalculationRows?.map((selected, i) => (
                <div
                  key={i}
                  {...(selected.description !== null && { style: { borderBottom: '1px solid var(--ion-color-light-shade)' } })}
                >
                  {((selected.secured_cost) || (!selected.secured_cost)) &&
                    (
                      <IonGrid>
                        <IonRow className={`ion-text-left ion-padding-top ${i % 2 === 0 ? styles.evenRow : ''}`}>
                          <IonCol
                            size={'4'}
                            className={`ion-text-left`}
                            {...((precalculationId) && {
                              onClick: () => props.onRowClick(selected)
                            })}
                          >
                            <ActivityCodeTile activityCode={selected.activity_code} name={selected.activity_code?.name} />
                          </IonCol>

                          <IonCol className={isDesktop ? 'ion-text-center' : 'ion-text-center ion-no-padding'}>
                            <BigUp.Label.Regular label={i18n.t('Quantity')} style={{ fontSize: '12px' }} />
                            <p>{Math.round(selected.quantity)}</p>
                          </IonCol>

                          <IonCol className={isDesktop ? 'ion-text-center' : 'ion-text-center ion-no-padding'}>
                            <BigUp.Label.Regular label={i18n.t('Cost/unit')} style={{ fontSize: '12px' }} />
                            <p>{formatNumber(selected.cost_per_unit)}</p>
                          </IonCol>

                          <IonCol {
                            ...isDesktop
                              ? { className: 'ion-text-center ion-no-padding' }
                              : { className: 'ion-text-center ion-no-padding' }}>

                            <BigUp.Label.Regular label={i18n.t('Total')} style={{ fontSize: '12px' }} />
                            <p>{formatNumber(selected.total)}</p>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    )}

                  {selected.description && (
                    <div className={`ion-text-left ion-padding-bottom ${i % 2 === 0 ? styles.evenRow : ''}`} style={{ paddingLeft: 5 }}>
                      <IonGrid className={classNames(styles['precalculations-comment-section'])}>
                        <IonRow className='ion-margin-left' >
                          <IonLabel >{`${t('Comment')}`} </IonLabel>
                        </IonRow>
                        {isDesktop
                          ? (
                            <IonRow className='ion-align-items-center ion-margin-left'>
                              <IonCol size='10' className='ion-margin-left'>
                                <span>
                                  {selected.description}
                                </span>
                              </IonCol>
                            </IonRow>
                          )
                          : (
                            <IonRow className='ion-align-items-flex-start' onClick={() => toggleDescription(i)} role='button'>
                              <IonCol size='10' className='ion-margin-left'>
                                <span>
                                  {showFullDescription === i
                                    ? <FadeInContainer isVisible={showFullDescription === i || !null}>{selected.description}</FadeInContainer>
                                    : selected.description && selected.description.length < 40 ? `${selected.description}` : `${selected.description.slice(0, 60)}...`}
                                </span>
                              </IonCol>
                              <IonCol size='2' className='ion-text-center'>
                                {selected.description.length > 40
                                  ? showFullDescription === i
                                    ? <IonIcon icon={chevronUp}></IonIcon>
                                    : <IonIcon icon={chevronDown}></IonIcon>
                                  : null}

                              </IonCol>
                            </IonRow>
                          )}
                      </IonGrid>
                    </div>
                  )}
                </div>
              ))
            )
            : <IonGrid>
              <IonRow className='ion-justify-content-center'>
                {handleEmptyListStates({
                  isEmpty: precalcalculationRows.length === 0,
                  error,
                  messages: {
                    empty: {
                      title: 'No activity codes',
                      message: t('No activity codes has been added to {precalculationName}',
                        'No activity codes has been added to {precalculationName}', {
                          precalculationName: props.selectedPrecalculation?.name
                        })
                    },
                    error: {
                      message: i18n.t('Failed to load precalculation rows')
                    }
                  },
                  search: props.search
                })}
              </IonRow>
            </IonGrid>
          }
        </div>
      </div>
      {precalcalculationRows && precalcalculationRows?.length > 0 && (
        <PaginateData
          currentPage={currentPage}
          totalPages={totalPages}
          pageStepper={pageStepper}
        />
      )}
    </React.Fragment>

  );
};

export default PrecalculationRows;
