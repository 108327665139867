import { IonList, IonLoading, useIonRouter } from '@ionic/react';
import { constructOutline, headsetOutline, newspaperOutline, settingsOutline, statsChartOutline } from 'ionicons/icons';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { networking } from '../../api/networking';
import DesktopWrapper from '../../components/DesktopWrapper';
import Panel from '../../components/Panels/Panel';
import { useAppSelector } from '../../hooks';
import { setIsLoading } from '../../reducers/loading';
import { setSelectedProject } from '../../reducers/project';
import store from '../../store';

interface HomepageLinks {
  icon: string;
  directTo: string;
  title: string;
}

const HomePage: React.FC = () => {
  const router = useIonRouter();
  const { t } = useTranslation();
  const isLoading = useAppSelector(state => state.loading.isLoading.projects);

  const HOMEPAGE_LINKS: HomepageLinks[] = [
    {
      icon: newspaperOutline,
      directTo: '/notice-board/',
      title: t('Notice board'),
    },
    {
      icon: statsChartOutline,
      directTo: '/financials/',
      title: t('Economy tools'),
    },
    {
      icon: constructOutline,
      directTo: '/tools/',
      title: t('Project tools'),
    },
    {
      icon: headsetOutline,
      directTo: '/support/',
      title: t('Support'),
    },
    {
      icon: settingsOutline,
      directTo: '/profile/settings',
      title: t('User settings'),
    }
  ];

  const assureIntroductionCompleted = () => {
    store.dispatch(setIsLoading({ name: 'projects', value: true }));
    networking.get('/api/v1/projects').then((response) => {
      if (response.data.length === 0) {
        router.push('/introduction');
      }
    }).finally(() => {
      store.dispatch(setIsLoading({ name: 'projects', value: false }));
    });
  };

  useEffect(() => {
    store.dispatch(setSelectedProject(undefined));
    assureIntroductionCompleted();
  }, []);

  return (
    <DesktopWrapper width='600px' alignment={'center'}>
      {isLoading
        ? <IonLoading />
        : (
          <IonList className='ion-padding-end ion-margin-top ion-padding-top' >
            {HOMEPAGE_LINKS.map((item, index) => {
              return (
                <Panel key={index}
                  onClick={() => router.push(item.directTo)}
                  panelTitle={item.title}
                  icon={{
                    start: {
                      icon: item.icon,
                      color: 'medium',
                    },
                  }}
                />
              );
            })}
          </IonList>
        )}
    </DesktopWrapper>
  );
};

export default HomePage;
