import { IonCol, IonGrid, IonRow, useIonRouter } from '@ionic/react';
import { t } from 'i18next';
import React from 'react';

import BigUp from '../../../components/UI';
import EmptyList from '../../../components/UI/EmptyList';
import useAcl from '../../../hooks/useAcl';
import { setSelectedProject } from '../../../reducers/project';
import store from '../../../store';
import ProjectCard from '../ProjectCard';

const ProjectList: React.FC = () => {
  const router = useIonRouter();
  const { getProjects, scopedAbilities } = useAcl();
  const projects = getProjects();

  const setProjectAndRedirect = (project: any) => {
    router.push(`/tools`);
    store.dispatch(setSelectedProject(project));
  };

  return (
    <IonGrid className='ion-padding ion-no-margin' style={{ width: '100%' }}>
      <IonRow className='ion-margin-vertical ion-justify-content-between'>
        <IonCol size='auto'>
          <BigUp.Title label={t('Projects')}/>
        </IonCol>
        {scopedAbilities.includes('can_create_projects') && (
          <IonCol size='auto'>
            <BigUp.Buttons.Regular
              title={t('Create new project')}
              color={'secondary'}
              onClick={() => router.push('/projects/create-new')}
            />
          </IonCol>
        )}
      </IonRow>
      {!projects?.length && (
        <IonRow className='ion-margin-top ion-padding-top ion-align-items-center ion-justify-content-center'>
          <IonCol>
            {
              scopedAbilities.includes('can_create_projects')
                ? (
                  <EmptyList
                    title={t('No projects found')}
                    message={t('Create a new project to get started')}
                    helperLinks={{
                      title: t('Create a new project'),
                      url: '/tools/create-project',
                    }}
                  />
                )
                : (
                  <EmptyList title={t('No projects found')} message={t("You can't create new project.")}/>
                )
            }
          </IonCol>
        </IonRow>
      )}
      {projects?.map((project, i) => {
        return (
          <IonRow key={i}>
            <IonCol>
              <ProjectCard
                key={i}
                project={project}
                onClick={() => setProjectAndRedirect(project)}
              />
            </IonCol>
          </IonRow>
        );
      })}
    </IonGrid>
  );
};

export default ProjectList;
