import { useIonRouter } from '@ionic/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import DesktopWrapper from '../../../components/DesktopWrapper';
import AppLayout from '../../../layout/AppLayout';
import ProjectForm from '../ProjectForm';
interface ProjectCreateProps {
  usesAppLayout?: boolean;
}
const ProjectCreate: React.FC<ProjectCreateProps> = ({ usesAppLayout = true }) => {
  const router = useIonRouter();
  const { t } = useTranslation();
  const form = () => (
    <DesktopWrapper width={'800px'}>
      <ProjectForm newProject onCancel={() => router.goBack()} />
    </DesktopWrapper>
  );

  return (
    usesAppLayout
      ? (
        <AppLayout header={{ hasHeader: true }} headerTitle={t('Create new project')}>
          {form()}
        </AppLayout>
      )
      : form()
  );
};

export default ProjectCreate;
