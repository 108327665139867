import React, { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import type { RouteProps } from 'react-router-dom';

import { useAppSelector } from '../hooks';
import ProtectedRoute from './ProtectedRoute';
import { WrappedRouteListener } from './WrappedRoute';
import { optionalOnboardingRoute } from '../constants/onboarding';
import useAcl from '../hooks/useAcl';

interface AbilitiesWatchedRouteParams extends RouteProps {
    path: string;
    type?: 'project' | 'client';
    requiredAbilities?: string[];
}

export const AbilitiesWatchedRoute: React.FC<AbilitiesWatchedRouteParams> = ({
  path,
  type = 'project',
  ...rest
}) => {
  const sanitisedPath = (path.endsWith('/') ? path.slice(0, -1) : path);
  return (
    <ProtectedRoute
      {...rest}
      path={rest.exact
        ? [`${sanitisedPath}`, `${sanitisedPath}${optionalOnboardingRoute}`]
        : path
      }
    >
      {rest.children}

      <WrappedRouteListener path={sanitisedPath}/>

      <AbilitiesWatchedRouteListener
        path={path}
        requiredAbilities={rest.requiredAbilities}
        type={type}
      />
    </ProtectedRoute>
  );
};

const AbilitiesWatchedRouteListener: React.FC<AbilitiesWatchedRouteParams> = (props) => {
  const { uuid } = useParams<{ uuid: string | undefined }>();
  const { fetchPermissions, getClient } = useAcl();
  const abilities = useAppSelector(state => state.authentication.abilities);
  const requiredAbilities = props.requiredAbilities || [];
  const isLoadingAbilities = useAppSelector(state => state.loading.isLoading.abilities);
  const sanitisedPath = (props.path.endsWith('/') ? props.path.slice(0, -1) : props.path);
  const history = useHistory();
  const location = useLocation();

  const checkAbilities = () => {
    if (!props.type || !uuid || isLoadingAbilities) {
      return;
    }
    if (requiredAbilities.length > 0) {
      const hasAbilities = abilities[props.type] &&
        abilities[props.type][uuid ?? ''] &&
        abilities[props.type][uuid ?? ''].length > 0 &&
        requiredAbilities.every(
          (ability) => abilities[props.type ?? 'project'][uuid ?? '']
            .some(
              (a) => a.name === ability
            )
        );
      if (!hasAbilities) {
        history.replace('/unauthorized', {
          required_abilities: requiredAbilities,
          type: props.type,
          requested_route: location.pathname
        });
      }
    }
  };

  useEffect(() => {
    if (uuid && props.type) {
      fetchPermissions({
        scope: {
          uuid,
          type: props.type
        }
      });
    } else if (!uuid) {
      const client = getClient();

      if (client) {
        fetchPermissions({
          scope: {
            uuid: client.id as string,
            type: 'client'
          }
        });
      }
    }
  }, [sanitisedPath]);

  useEffect(() => {
    checkAbilities();
  }, [abilities, requiredAbilities]);

  useEffect(() => {
    checkAbilities();
  }, []);

  return (
    <></>
  );
};
