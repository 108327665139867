
import { useTranslation } from 'react-i18next';

import SideMenuLayout from '../../../components/UI/SideMenu/SideMenuLayout';
import UserSettings from '../../User/UserSettings';

const SettingsPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <SideMenuLayout baseURL={'tools'} title={t('Settings')}>
      <UserSettings />
    </SideMenuLayout>
  );
};

export default SettingsPage;
