import { IonCol, IonContent, IonGrid, IonPage, IonRow } from '@ionic/react';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import styles from './Login.module.scss';
import LoginSegment from './LoginSegment';
import backgroundImage from '../../assets/images/Economy_desktop.jpg';
import AppVersion from '../../components/AppVersion';
import Avatar from '../../components/Avatar';
import LoginForm from '../../components/LoginForm';
import BigUp from '../../components/UI';
import BankID from '../../components/UI/BankID/BankID';
import { useAppSelector } from '../../hooks';
import { setUser } from '../../reducers/authentication';
import store from '../../store';

const Login: React.FC = () => {
  const [loginMethod, setLoginMethod] = useState<'email' | 'bankID'>('email');
  const { t } = useTranslation();
  useEffect(() => {
    store.dispatch(setUser(null));
  }, []);
  const isDesktop = useAppSelector(state => state.desktopView.isDesktop);

  return (
    <IonPage>
      <IonContent
        style={{
          '--background': 'none',
          backgroundImage: `var(--ion-gradient-overlay), url(${backgroundImage})`,
          backgroundSize: 'cover',
          objectFit: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <IonGrid>
          <IonRow className="ion-justify-content-center ion-align-items-center">
            <IonCol size={'auto'}>
              <Link to={'/login'}>
                <img src={BigUp.Logo} alt={t('BigUp logo')} className={styles['bigup-logo']} />
              </Link>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonGrid style={{ background: 'var(--ion-color-light)', maxWidth: '500px', borderRadius: '20px', }} className={classNames('ion-justify-content-start ion-align-items-center', styles['vertical-aligned-grid'])}>
          <IonRow className='ion-justify-content-center'>
            <IonCol className='ion-padding-horizontal'>
              <LoginSegment loginMethod={loginMethod} setLoginMethod={setLoginMethod} />
            </IonCol>
          </IonRow>
          <IonRow className='ion-justify-content-center ion-align-items-center'>
            <IonCol size='auto'>
              {loginMethod === 'email' && (
                <>
                  <div className={styles['login-form-container']}>
                    <Avatar.AvatarImage style={{ width: 100, height: 100 }} avatarSource='placeholder' />
                    <LoginForm />
                  </div>
                  {!isDesktop && <div className={styles['app-version-container-login-page']}>
                    <AppVersion />
                  </div>}
                </>
              )}

              {loginMethod === 'bankID' && (
                <div style={{ width: 350 }}>
                  <BankID.BankIdLogin />
                </div>
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Login;
