import { IonPage, IonRouterOutlet } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import React from 'react';
import { Redirect } from 'react-router';

import ControlOfExecution from './ControlOfExecution';
import ControlOfExecutionDetailed from './ControlOfExecution/ControlOfExecutionDetailed';
import FortnoxPage from './FortnoxPage';
import ProjectMappingPage from './FortnoxPage/ProjectMappingPage';
import ProjectDocuments from './ProjectDocuments/ProjectDocuments';
import SettingsPage from './SettingsPage';
import SiteAccessRequests from './SiteAccessRequests';
import WorkingEnviroment from './WorkingEnviroment';
import WorkingEnvironmentReport from './WorkingEnviroment/WorkingEnvironmentReport';
import MobilePanels from '../../components/MobilePanels';
import SideMenuLayout from '../../components/UI/SideMenu/SideMenuLayout';
import { matchUuid } from '../../constants/matchUuid';
import { useAppSelector } from '../../hooks';
import i18n from '../../i18n';
import { AbilitiesWatchedRoute } from '../../routes/AbilitiesWatchedRoute';
import AccessManagementPage from '../ClientAccount/AccessManagementPage';
import MassAssignmentPage from '../ClientAccount/MassAssignmentPage';
import DocumentPage from '../Document';
import CategoryPage from '../Document/Category';
import EditDocumentTypePage from '../Document/Category/EditDocumentTypePage';
import DocumentsEditPage from '../Document/DocumentEditPage';
import TagPage from '../Tags/TagPage';
import TagsPage from '../Tags/TagsPage';
import Teams from '../Teams';
import TeamInvite from '../Teams/Invite/TeamInvite';
import Team from '../Teams/Team/Team';
import TeamForm from '../Teams/TeamForm/TeamForm';
import WorksitePage from '../Worksite';
import WorkSiteFormPage from '../Worksite/form';
import CategoriesSettingPage from './SettingsPage/WorkPreparationSettingsPage/CategoriesSettingPage';
import RowsSettingPage from './SettingsPage/WorkPreparationSettingsPage/RowsSettingPage';
import LayerUpload from '../Worksite/form/LayerUpload/LayerUpload';
import WorksiteMapPage from '../Worksite/map';
import NodesSettingPage from './SettingsPage/WorkPreparationSettingsPage/NodesSettingPage';
import SectionsSettingPage from './SettingsPage/WorkPreparationSettingsPage/SectionsSettingPage';
import SiteAccessRequestsSettings from './SiteAccessRequests/Settings/Settings';
import SideMenuV2Layout from '../../components/UI/SideMenu/V2/SideMenuV2Layout';

const ToolsPage: React.FC = () => {
  const selected: E2U.V1.Models.Project | undefined = useAppSelector((state) => state.onboarding.selectedProject);
  const project: E2U.V1.Models.Project | undefined = useAppSelector((state) => state.project.selectedProject);
  const selectedProject = selected || project;

  return (
    <IonPage>
      <IonRouterOutlet>
        <AbilitiesWatchedRoute path={`/tools/:uuid(${matchUuid})`} exact={true}>
          <MobilePanels type={'tools'} />
        </AbilitiesWatchedRoute>

        {/* CONTROL OF EXECUTION */}
        <AbilitiesWatchedRoute
          requiredAbilities={['can_view_control_of_executions']}
          path={`/tools/:uuid(${matchUuid})/control-of-execution/:coe_id(${matchUuid})`}
        >
          <SideMenuV2Layout>
            <ControlOfExecutionDetailed />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>
        <AbilitiesWatchedRoute
          requiredAbilities={['can_view_control_of_executions']}
          path={`/tools/:uuid(${matchUuid})/control-of-execution/`}
          exact
        >
          <SideMenuV2Layout>
            <ControlOfExecution />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>
        {/* WORKING ENVIRONMENT */}
        <AbilitiesWatchedRoute
          requiredAbilities={['can_view_working_environments']}
          path={`/tools/:uuid(${matchUuid})/working-environment/`}
        >
          <SideMenuV2Layout>
            <WorkingEnviroment />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>
        {/* SITE ACCESS REQUESTS */}
        <AbilitiesWatchedRoute
          requiredAbilities={['can_view_site_access_requests']}
          path={`/tools/:uuid(${matchUuid})/site-access-requests/settings`}
          exact
        >
          <SideMenuV2Layout>
            <SiteAccessRequestsSettings />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>
        <AbilitiesWatchedRoute
          requiredAbilities={['can_view_site_access_requests']}
          path={`/tools/:uuid(${matchUuid})/site-access-requests/`}
          exact
        >
          <SideMenuV2Layout>
            <SiteAccessRequests />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>

        {/* DOCUMENTS */}
        <AbilitiesWatchedRoute
          requiredAbilities={['can_view_documents', 'can_view_working_environments']}
          path={`/tools/:uuid(${matchUuid})/documents/reports/working_environment/:working_environment_uuid(${matchUuid})/:entry_uuid(${matchUuid})?`}
          exact
        >
          <SideMenuV2Layout>
            <WorkingEnvironmentReport />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>

        <AbilitiesWatchedRoute
          requiredAbilities={['can_view_documents', 'can_view_working_environments']}
          path={`/tools/${selectedProject?.id}/documents/reports/working_environment_entry/:working_environment_entry_uuid(${matchUuid})`}
          exact
        >
          <SideMenuV2Layout>
            <WorkingEnvironmentReport />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>
        <AbilitiesWatchedRoute
          path={`/tools/:uuid(${matchUuid})/documents/:documentUuid(${matchUuid})/edit`}
          exact
          requiredAbilities={['can_view_documents', 'can_edit_documents']}
        >
          <SideMenuV2Layout>
            <DocumentsEditPage />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>
        <AbilitiesWatchedRoute

          requiredAbilities={['can_view_documents']}
          path={`/tools/:uuid(${matchUuid})/documents/:documentUuid(${matchUuid})`}
          exact
        >
          <SideMenuV2Layout>
            <DocumentPage />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>
        <AbilitiesWatchedRoute
          path={`/tools/:uuid(${matchUuid})/documents/`}
          exact
          requiredAbilities={['can_view_documents']}
        >
          <SideMenuV2Layout>
            <ProjectDocuments />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>

        {/* WORKSITES */}
        <AbilitiesWatchedRoute
          path={`/tools/:uuid(${matchUuid})/worksites/:worksite_id(${matchUuid}/upload)`}

          requiredAbilities={['can_view_worksites', 'can_edit_worksites']}

        >
          <SideMenuV2Layout>
            <LayerUpload />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>
        <AbilitiesWatchedRoute
          path={`/tools/:uuid(${matchUuid})/worksites/:worksite_id(${matchUuid}|add)`}
          exact
          requiredAbilities={['can_view_worksites', 'can_edit_worksites']}
        >
          <SideMenuV2Layout title={i18n.t('Worksites')}>

            <WorkSiteFormPage />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>
        <AbilitiesWatchedRoute
          path={`/tools/:uuid(${matchUuid})/worksites`}
          exact
          requiredAbilities={['can_view_worksites']}
        >
          <SideMenuV2Layout title={i18n.t('Worksites')}>
            <WorksitePage />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>

        <AbilitiesWatchedRoute
          path={`/tools/:uuid(${matchUuid})/map/:worksite_layer_id?`}
          requiredAbilities={['can_view_worksites']}
        >
          <SideMenuV2Layout canReturn title={i18n.t('Map')}>
            <WorksiteMapPage />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>

        <AbilitiesWatchedRoute
          path={`/tools/:uuid(${matchUuid})/settings`}
          exact
          requiredAbilities={['can_edit_projects']}
        >
          <SideMenuV2Layout title={i18n.t('Settings')}>
            <SettingsPage />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>

        {/* TAGS */}
        <AbilitiesWatchedRoute
          path={`/tools/:uuid(${matchUuid})/settings/tags/:tag_category_id(${matchUuid})`}
          requiredAbilities={['can_view_tags', 'can_edit_tags']}
        >
          <SideMenuV2Layout canReturn title={i18n.t('Tags')}>
            <TagPage />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>
        <AbilitiesWatchedRoute
          path={`/tools/:uuid(${matchUuid})/settings/tags/`}
          exact
          requiredAbilities={['can_view_tags', 'can_edit_tags']}
        >
          <SideMenuV2Layout canReturn title={i18n.t('Tags')}>
            <TagsPage />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>

        <AbilitiesWatchedRoute
          path={`/tools/:uuid(${matchUuid})/settings/document-types/:category_id(${matchUuid})/`}
          exact

          requiredAbilities={['can_view_document_types', 'can_edit_document_types']}

        >
          <SideMenuV2Layout canReturn title={i18n.t('Document types')}>
            <EditDocumentTypePage />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>

        {/* CATEGORIES */}
        <AbilitiesWatchedRoute
          path={`/tools/:uuid(${matchUuid})/settings/document-types/`}
          exact

          requiredAbilities={['can_view_document_types', 'can_edit_document_types']}

        >
          <SideMenuV2Layout canReturn title={i18n.t('Document types')}>
            <CategoryPage />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>
        {/* TEAM */}
        <AbilitiesWatchedRoute
          path={`/tools/:uuid(${matchUuid})/settings/team/:team_id(${matchUuid})`}
          exact

          requiredAbilities={['can_edit_projects']}

        >
          <SideMenuV2Layout canReturn title={i18n.t('Team')}>
            <Team />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>

        <AbilitiesWatchedRoute
          path={`/tools/:uuid(${matchUuid})/team/:team_id(${matchUuid})/invite`}
          exact

          requiredAbilities={['can_edit_projects']}

        >
          <SideMenuV2Layout canReturn title={i18n.t('Invite')}>
            <TeamInvite />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>

        <AbilitiesWatchedRoute
          path={`/tools/:uuid(${matchUuid})/settings/team/:team_id(${matchUuid})/edit`}
          exact

          requiredAbilities={['can_edit_projects']}

        >
          <SideMenuV2Layout canReturn title={i18n.t('Edit team')}>
            <TeamForm />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>

        <AbilitiesWatchedRoute
          path={`/tools/:uuid(${matchUuid})/settings/team/create`}
          exact

          requiredAbilities={['can_edit_projects']}

        >
          <SideMenuV2Layout canReturn title={i18n.t('Create team')}>
            <TeamForm />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>

        <AbilitiesWatchedRoute
          path={`/tools/:uuid(${matchUuid})/settings/teams`}

          requiredAbilities={['can_edit_projects']}

        >
          <SideMenuV2Layout canReturn title={i18n.t('Teams')}>
            <Teams />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>

        <AbilitiesWatchedRoute
          path={`/tools/:uuid(${matchUuid})/settings/fortnox`}
          exact

          requiredAbilities={['can_view_fortnox', 'can_edit_fortnox']}

        >
          <SideMenuV2Layout canReturn title={i18n.t('Fortnox')}>
            <FortnoxPage />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>

        <AbilitiesWatchedRoute
          path={`/tools/:uuid(${matchUuid})/settings/fortnox/integration/:integration_id(${matchUuid})`}
          exact

          requiredAbilities={['can_view_fortnox', 'can_edit_fortnox']}

        >
          <SideMenuV2Layout canReturn title={i18n.t('Fortnox')}>
            <ProjectMappingPage />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>

        <AbilitiesWatchedRoute
          path={`/tools/:uuid(${matchUuid})/settings/access`}
          exact={true}

          // requiredAbilities={['can_edit_projects']}

        >
          <SideMenuV2Layout canReturn title={i18n.t('Access management')}>
            <AccessManagementPage type={'projects'} />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>

        <AbilitiesWatchedRoute
          path={`/tools/:uuid(${matchUuid})/settings/access/add/:tool?`}

          requiredAbilities={['can_edit_projects']}

        >
          <SideMenuV2Layout canReturn title={i18n.t('Mass assignment')}>
            <MassAssignmentPage type={'projects'} />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>

        <AbilitiesWatchedRoute
          path={`/tools/:uuid(${matchUuid})/settings/work-preparation`}
          exact={true}
          // requiredAbilities={['can_edit_projects']}
        >
          <Redirect to={`/tools/${selectedProject?.id}/settings/work-preparation/nodes`} />
        </AbilitiesWatchedRoute>

        <AbilitiesWatchedRoute
          path={`/tools/:uuid(${matchUuid})/settings/work-preparation/nodes`}
          exact={true}
          // requiredAbilities={['can_edit_projects']}
        >
          <SideMenuV2Layout canReturn>
            <NodesSettingPage type={'projects'} />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>

        <AbilitiesWatchedRoute
          path={`/tools/:uuid(${matchUuid})/settings/work-preparation/node/:node_id(${matchUuid})`}
          exact={true}
          // requiredAbilities={['can_edit_projects']}
        >
          <SideMenuV2Layout canReturn>
            <SectionsSettingPage type={'projects'} />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>

        <AbilitiesWatchedRoute
          path={`/tools/:uuid(${matchUuid})/settings/work-preparation/section/:section_id(${matchUuid})`}
          exact={true}
          // requiredAbilities={['can_edit_projects']}
        >
          <SideMenuV2Layout canReturn>
            <CategoriesSettingPage type={'projects'} />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>

        <AbilitiesWatchedRoute
          path={`/tools/:uuid(${matchUuid})/settings/work-preparation/category/:category_id(${matchUuid})`}
          exact={true}
          // requiredAbilities={['can_edit_projects']}
        >
          <SideMenuV2Layout canReturn>
            <RowsSettingPage type={'projects'} />
          </SideMenuV2Layout>
        </AbilitiesWatchedRoute>

      </IonRouterOutlet>
    </IonPage>
  );
};

export default ToolsPage;
