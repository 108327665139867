import { IonInput } from '@ionic/react';
import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import BigUp from '..';
import type { InputProps } from './Input.interface';
import styles from './Input.module.scss';
import { ValidationBadge } from './ValidationBadge';

const OutlinedInput: React.FC<InputProps<any>> = (props) => {
  const methods = useFormContext();
  const error = useMemo(() => {
    return props.forceError || methods.formState.errors[props.register]?.message;
  }, [methods.formState.errors, props.forceError]);

  const setServerErrors = () => {
    methods.setError(props.register, {
      type: 'server',
      message: props.serverError?.toString(),
    }, {
      shouldFocus: true,
    });
  };

  useEffect(() => {
    if (props.serverError) {
      setServerErrors();
    }
  }, [methods.formState.errors, props.serverError]);

  return (
    <>
      {props.customLabel && (
        <BigUp.Label.Thick
          label={`${props.customLabel} ${props.validation.required ? '*' : ''}`}
          className={`ion-no-margin ${styles['outlined-input-label']}`}
          position='stacked'
        />
      )}
      <IonInput
        mode='md'
        fill='outline'
        className={`ion-margin-left ${styles['outlined-input']}`}
        autoCapitalize='sentences'
        clearInput={false}
        type={props.type}
        inputmode={props.inputMode}
        placeholder={props.placeholder}
        debounce={300}
        helperText={props.helperText && props.helperText}
        {...props}
        {...props.register && methods.register(props.register, {
          ...props.validation,
        })}

      />
      {error && (
        <div className={`ion-no-margin ${styles['outlined-input-error-badge']}`}>
          <ValidationBadge>
            {error}
          </ValidationBadge>
        </div>
      )}
    </>
  );
};

export default OutlinedInput;
