import { IonCol, IonContent, IonFabButton, IonGrid, IonIcon, IonItem, IonModal, IonRow, IonText, useIonAlert } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import type { WorkingEnvironmentItem } from '@techlove/easy2use-typings/src/V1/Models/WorkingEnvironmentItem';
import classNames from 'classnames';
import { chatboxEllipsesOutline, checkmark, close, pencil } from 'ionicons/icons';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { Status } from './enums';
import weStyles from './WorkingEnvironment.module.scss';
import WorkingEnvironmentEntryReport from './WorkingEnvironmentEntry/WorkingEnvironmentEntryReport';
import WorkingEnviromentList from './WorkingEnvironmentList';
import { networking } from '../../../api/networking';
import BigUp from '../../../components/UI';
import ButtonCTA from '../../../components/UI/Buttons/CTA/ButtonCTA';
import { handleEmptyListStates } from '../../../components/UI/EmptyList';
import HeaderBorderLeft from '../../../components/UI/modals/HeaderBorderLeft';
import toasters from '../../../components/UI/Toasts';
import { ionicColours } from '../../../components/UI/variables';
import { emptyListMessages } from '../../../constants/messages';
import { useAppSelector } from '../../../hooks';
import useModal from '../../../hooks/useModal';
import { setWorkingEnvironmentEntry } from '../../../reducers/workingEnvironmentEntryReducer';
import store from '../../../store';
import modalStyles from '../../Onboarding/Components/containers/styles/ModalContainers.module.scss';

const WorkingEnviroment: React.FC = () => {
  const { t } = useTranslation();
  const { closeModal, isModalOpen, toggleModal } = useModal();
  const [toggleReport, setToggleReport] = useState<boolean>(false);
  const [dailyWorkingEnvironment, setDailyWorkingEnvironment] = useState<E2U.V1.Models.WorkingEnvironment | undefined>();
  const [environmentCategories, setEnvironmentCategories] = useState<E2U.V1.Models.WorkingEnvironmentCategory[] | undefined>();
  const [error, setError] = useState<string | undefined>();
  const [presentAlert] = useIonAlert();
  const { uuid } = useParams<{ uuid: string }>();
  const selectedWorkingEnvironmentEntry = useAppSelector(state => state.workingEnvironmentEntry.workingEnvironmentEntry);
  const [isDenial, setIsDenial] = useState<boolean>(false);

  const getCategory = () => {
    networking.get(`/api/v1/working_environment_categories`)
      .then((response: E2U.V1.Response.Success<E2U.V1.Objects.PaginatedData<E2U.V1.Models.WorkingEnvironmentCategory>>) => {
        setEnvironmentCategories(response.data.data.records);
      });
  };

  const openCreateList = () => {
    toggleModal();
  };

  const getDailyEnvironment = () => {
    networking.get(`/api/v1/projects/${uuid}/working_environment?with=entries_by_category`)
      .then((response: E2U.V1.Response.Success<E2U.V1.Models.WorkingEnvironment>) => {
        setDailyWorkingEnvironment(response.data.data);
      })
      .catch((error) => {
        setError(error.message);
      });
    getCategory();
  };

  const checkEnvironment = (workingEnvironmentId: string, check: string, workingEnvironmentItem: WorkingEnvironmentItem | undefined) => {
    networking.post(`/api/v1/working_environments/${workingEnvironmentId}/${check}/${workingEnvironmentItem?.id}`)
      .finally(() => getDailyEnvironment());
  };

  const closeEnvironmentHandler = (checkAll = false) => {
    networking.post(`/api/v1/working_environments/${dailyWorkingEnvironment?.id}/complete`, {
      check_all: checkAll,
    })
      .then(() => {
        getDailyEnvironment();
        toasters.createToast({
          message: t('Todays environment has been saved, good job!'),
          background: 'var(--ion-color-light)',
        }, 'success');
      })
      .catch((error) => Sentry.captureException(error));
  };

  const closeAllEnvironments = (allApproved: any) => {
    presentAlert(
      {
        header: t('Approve all environments?'),
        message: allApproved ? (t('Saving the environment will clear your task for today.')) : (t('All environments have not been approved, do you want to approve all environments?')),
        buttons: [
          {
            text: t('Cancel'),
            role: 'cancel',

          },
          {
            text: t('Approve'),
            role: Status.approve,
            handler: () => {
              closeEnvironmentHandler(true);
            }
          }
        ]
      }
    );
  };

  const toggleReportHandler = (entry: E2U.V1.Models.WorkingEnvironmentItem | undefined, isDeny = false) => {
    setToggleReport(!toggleReport);
    store.dispatch(setWorkingEnvironmentEntry(entry));
    setIsDenial(isDeny);
  };

  const messages = {
    empty: {
      title: emptyListMessages.working_environment.empty_title,
      message: emptyListMessages.working_environment.empty_message
    },
    error: {
      message: error || '',
    },
  };

  const check = dailyWorkingEnvironment?.entries_by_category && Object.keys(dailyWorkingEnvironment?.entries_by_category).length === 0;

  const handleCheckEnvironment = () => {
    if (selectedWorkingEnvironmentEntry) {
      if (!isDenial) {
        getDailyEnvironment();
      } else {
        checkEnvironment(
          selectedWorkingEnvironmentEntry?.environment_id,
          Status.deny,
          selectedWorkingEnvironmentEntry.item
        );
      }
    }
  };

  useEffect(() => {
    getDailyEnvironment();
  }, []);

  return (
    <React.Fragment>
      <div className="ion-padding">
        <IonGrid>
          <IonRow>
            <IonCol>
              <BigUp.Title label={t('Working environment')} />
            </IonCol>
          </IonRow>
          <IonRow className='ion-align-items-center ion-justify-content-between'>
            <IonCol size='6' color='danger'>
              <IonItem className='ion-no-padding' color={'none'} lines='none'>
                <IonText>
                  <h3>
                    {DateTime.now().toLocaleString(DateTime.DATE_MED)}
                  </h3>
                </IonText>
              </IonItem>
            </IonCol>
            <IonCol sizeLg='3' className='ion-text-right'>
              <ButtonCTA
                clickHandler={toggleModal}
                content={!dailyWorkingEnvironment?.entries_by_category
                  ? t('Create list')
                  : t('Edit list')
                }
                icon={pencil}
                backgroundColour={ionicColours.secondary}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            {handleEmptyListStates({ isEmpty: check, error, messages })}
          </IonRow>
          {(dailyWorkingEnvironment && dailyWorkingEnvironment.entries_by_category) &&
            Object.keys(dailyWorkingEnvironment?.entries_by_category).map((categoryId, index) => {
              const findCategoryId = dailyWorkingEnvironment?.entries_by_category[categoryId];

              const findCompleted = findCategoryId.every((entry) => {
                return (entry.status === Status.completed || entry.status === Status.faulty);
              });

              const findEnvironmentCategory = environmentCategories?.find((category) => {
                return (category.id === categoryId);
              });

              const markAllInCategoryAsComplete = () => {
                findCategoryId.filter((checkEntry: any) => {
                  return (
                    checkEntry.status !== (Status.faulty || Status.completed) &&
                    checkEnvironment(
                      dailyWorkingEnvironment.id as string,
                      Status.approve,
                      checkEntry.item
                    )
                  );
                });
              };

              return (
                <React.Fragment key={index}>
                  <IonRow
                    className='ion-justify-space-between ion-align-items-center ion-padding-right ion-margin-top'
                    style={{ backgroundColor: ionicColours.light, fontSize: '16px' }}>
                    <IonCol >
                      <IonText color={'tertiary'} >
                        <h5 style={{ fontWeight: 600, opacity: '0.8' }}>
                          {`${findEnvironmentCategory?.code} ${findEnvironmentCategory?.name}`}
                        </h5>
                      </IonText>
                    </IonCol>
                    <IonCol className='ion-text-right'>
                      <ButtonCTA
                        buttonShadow={'0'}
                        content={findCompleted && true
                          ? t('Completed')
                          : t('Approve remaining')}
                        backgroundColour={'none'}
                        textColour={ionicColours.medium}
                        clickHandler={() => {
                          presentAlert(
                            {
                              header: t('Approve all environments?'),
                              message: t('All environments have not been approved, do you want to approve all environments?'),
                              buttons: [
                                {
                                  text: t('Cancel'),
                                  role: 'cancel',
                                },
                                {
                                  text: t('Approve'),
                                  role: Status.approve,
                                  handler: () => {
                                    markAllInCategoryAsComplete();
                                  }
                                }
                              ]
                            }
                          );
                        }}
                      />
                    </IonCol>
                  </IonRow>
                  {findCategoryId.map((entry: any, idx: number) => {
                    const itemId = entry.item.id;
                    const entryStatusComplete = entry.status === Status.completed;
                    const entryStatusFaulty = entry.status === Status.faulty;
                    return (
                      <IonRow
                        key={idx}
                        className='ion-align-items-center ion-justify-content-between'
                        style={{ borderBottom: '1px solid var(--ion-color-medium)' }}
                      >
                        <IonCol size='6' className='ion-padding'>
                          <p className='ion-no-margin' style={{ fontSize: 'var(--ion-font-size-xs)' }}>
                            {entry.item.name}
                          </p>
                        </IonCol>
                        <IonRow className='ion-justify-content-end'>
                          <IonCol className='ion-no-margin ion-margin-end'>
                            {itemId &&
                              (entryStatusComplete || entryStatusFaulty) &&
                              (
                                <IonFabButton
                                  size='small'
                                  color={entry.reports.length > 0 ? 'primary' : 'none'}
                                  className={classNames('ion-no-margin', entry.reports.length === 0 && weStyles['working-environment-is-checked'])}
                                  onClick={() => { toggleReportHandler(entry); }}>
                                  <IonIcon
                                    icon={chatboxEllipsesOutline}
                                    {...itemId &&
                                      entry.reports.length > 0 &&
                                      (entryStatusComplete || entryStatusFaulty)
                                      ? { color: 'light' }
                                      : { color: 'medium' }
                                    }
                                  />
                                </IonFabButton>
                              )
                            }
                          </IonCol>

                          <IonCol className='ion-no-margin ion-margin-end' onClick={(e) => e.stopPropagation()}>
                            <IonFabButton
                              style={{ '--box-shadow': 'none' }}
                              size='small'
                              onClick={(e) => {
                                checkEnvironment(
                                  dailyWorkingEnvironment.id as string,
                                  Status.approve,
                                  entry.item
                                );
                                e.stopPropagation();
                              }}
                              {...itemId &&
                                entryStatusComplete
                                ? { color: 'success', className: 'ion-no-margin' }
                                : { color: 'none', className: classNames('ion-no-margin', weStyles['working-environment-is-checked']) }
                              }
                            >
                              <IonIcon
                                icon={checkmark}

                                {...itemId && entryStatusComplete ? { color: 'light' } : { color: 'medium' }}
                              />
                            </IonFabButton>
                          </IonCol>

                          <IonCol className='ion-no-margin '>
                            {itemId && entryStatusFaulty
                              ? (
                                <IonFabButton
                                  size='small'
                                  className={'ion-no-margin'}
                                  color={'danger'}
                                  onClick={() => {
                                    toggleReportHandler(entry);
                                  }}>
                                  <IonIcon icon={close} color='light' />
                                </IonFabButton>
                              )
                              : (
                                <IonFabButton
                                  size='small'
                                  className={classNames('ion-no-margin', weStyles['working-environment-is-checked'])}
                                  color={'none'}
                                  onClick={(e) => {
                                    toggleReportHandler(entry, true);
                                  }}>
                                  <IonIcon icon={close} color='medium' />
                                </IonFabButton>
                              )
                            }
                          </IonCol>
                        </IonRow>
                      </IonRow>
                    );
                  })}
                </React.Fragment>
              );
            })
          }
          <IonRow>
            <IonCol className='ion-text-right ion-margin-top' >
              {!check && (
                <BigUp.Buttons.Regular
                  onClick={() => closeAllEnvironments(
                    dailyWorkingEnvironment?.entries_by_category
                      ? Object.keys(dailyWorkingEnvironment?.entries_by_category)
                        .every((categoryId) => {
                          return dailyWorkingEnvironment?.entries_by_category[categoryId]
                            .every((entry) => {
                              return (entry.status === Status.completed || entry.status === Status.faulty);
                            });
                        })
                      : false
                  )}
                  color={'secondary'}
                  title={t('Save environment')}
                />
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
      <IonModal
        isOpen={isModalOpen}
        onIonModalDidDismiss={closeModal}
        className={modalStyles['app-default-modal']}>
        <HeaderBorderLeft
          borderColour={ionicColours.secondary}
          title={t('Create')}
          clickHandler={openCreateList}
        />
        <IonContent className='ion-padding'>
          <WorkingEnviromentList
            environment={dailyWorkingEnvironment}
            selectedItems={dailyWorkingEnvironment?.entries_by_category}
            closeModal={toggleModal}
            updateEnvironment={getDailyEnvironment}
          />
        </IonContent>
      </IonModal>

      <IonModal
        isOpen={toggleReport}
        onIonModalDidDismiss={() => { setToggleReport(false); }}
        className={modalStyles['app-default-modal']}
      >
        <HeaderBorderLeft
          borderColour={ionicColours.tertiary}
          title={t('Report')}
          clickHandler={() => toggleReportHandler(undefined)}
        />
        <IonContent className='ion-padding'>
          <WorkingEnvironmentEntryReport
            closeModal={() => toggleReportHandler(undefined)}
            onReportSaved={handleCheckEnvironment}
          />
        </IonContent>
      </IonModal>
    </React.Fragment >
  );
};
export default WorkingEnviroment;
