import { IonDatetime } from '@ionic/react';
import { DateTime } from 'luxon';
import type { ComponentProps } from 'react';
import { useFormContext } from 'react-hook-form';

import i18n from '../../../i18n';
import type { DateIDs, DateRegisters } from '../../../pages/NoticeBoard/Form/form.interface';

export interface DateTimeContentProps {
  id: DateIDs | string;
  dateRegister: DateRegisters | string;
  presentation?: ComponentProps<typeof IonDatetime>['presentation'];
  max?: string | any;
  min?: string | any;
}
const DateTimeContent: React.FC<DateTimeContentProps> = ({ dateRegister, id, max, min, presentation = 'time-date' }) => {
  const methods = useFormContext();

  const zone = DateTime.local().zoneName;
  const locale = DateTime.local().locale;

  const adjustDateForTimezone = (date: DateTime) => {
    return date.setZone(zone, { keepLocalTime: true }).toISO();
  };

  const findCurrentYear = () => {
    const now = DateTime.local().setZone(zone);
    return now.plus({ years: 20 }).endOf('year').toJSDate();
  };

  const formatDefaultValues = (date: string) => {
    return DateTime.fromISO(date).toISO();
  };

  const minDate = adjustDateForTimezone(DateTime.local().setZone(zone).startOf('day'));
  const maxDate = adjustDateForTimezone(DateTime.fromJSDate(findCurrentYear()).setZone(zone).endOf('day'));
  const todays = adjustDateForTimezone(DateTime.local().setZone(zone));
  return (
    <IonDatetime
      onIonChange={(e) => {
        const date = e.detail.value as string;
        methods.setValue(dateRegister, date);
      }}
      value={methods.getValues(dateRegister) ? formatDefaultValues(methods.getValues(dateRegister)) : (methods.setValue(dateRegister, todays), todays)}
      showDefaultButtons
      locale={locale}
      doneText={i18n.t('OK')}
      cancelText={i18n.t('Cancel')}
      presentation={presentation}
      min={min ?? minDate}
      max={max ?? maxDate}
      id={id}
    />
  );
};

export default DateTimeContent;
