import type { E2U } from '@techlove/easy2use-typings';
import classNames from 'classnames';
import { chevronBack, chevronForward, homeSharp, layersSharp } from 'ionicons/icons';
import { useHistory } from 'react-router';

import styles from './WorksiteMaps.module.scss';
import FabButton from '../../../components/UI/Buttons/Fabs/FabButton';

interface BottomButtonProps {
  pageNumber: number;
  setPageNumber: (pageNumber: number) => void;
  numPages: number;
  selectedProject: E2U.V1.Models.Project | undefined;
}
const BottomButtons: React.FC<BottomButtonProps> = ({ numPages, pageNumber, selectedProject, setPageNumber }) => {
  const history = useHistory();

  const handlePageBack = () => {
    if (pageNumber > 1) setPageNumber(pageNumber - 1);
  };

  const handlePageForward = () => {
    if (pageNumber < numPages) setPageNumber(pageNumber + 1);
  };

  return (
    <>
      <FabButton
        className={classNames(styles.sectionSelectButton, styles.sectionSelectButtonLeft)}
        icon={{ icon: homeSharp }}
        onClick={() => history.push(`/tools/${selectedProject?.id}/worksites`)}
      />
      {numPages > 1 && (
        <>
          <FabButton
            className={styles.sectionSelectButton}
            color={'none'}
            style={{ left: '33%', zIndex: 40 }}
            icon={{ icon: chevronBack }}
            onClick={handlePageBack}
            button={{ disabled: pageNumber === 1 }}
          />

          <FabButton
            className={styles.sectionSelectButton}
            color={'none'}
            style={{ right: '33%', zIndex: 40 }}
            icon={{ icon: chevronForward }}
            onClick={handlePageForward}
            button={{ disabled: pageNumber === numPages }}
          />
        </>

      )}

      <FabButton
        className={classNames(styles.sectionSelectButton, styles.sectionSelectButtonRight)}
        id='worksites-open-modal'
        icon={{ icon: layersSharp }}
      />
    </>
  );
};

export default BottomButtons;
