import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonCol,
  IonGrid,
  IonIcon,
  IonImg,
  IonRow,
  IonToolbar
} from '@ionic/react';
import { closeOutline, logOutOutline } from 'ionicons/icons';
import React from 'react';

import { useAppSelector } from '../../../../hooks';
import useLogout from '../../../../hooks/useLogout';
import useProfilePicture from '../../../../hooks/useProfilePicture';
import i18n from '../../../../i18n';
import type { OnboardingInterface } from '../../interfaces/Onboarding.interface';
import styles from '../modals/styles/PersonalOverview.module.scss';

const PersonalOverviewToolbar: React.FC<OnboardingInterface> = (props) => {
  const user = useAppSelector(state => state.authentication.user);
  const profilePicture = useProfilePicture();
  const logout = useLogout();

  return (
    <React.Fragment>
      <IonToolbar className={styles['personaloverview-toolbar']}>
        <IonGrid>
          <IonRow class="ion-justify-content-evenly ion-text-center">
            <IonCol size="auto">
              <IonButtons>
                <IonButton color={'light'} shape="round" fill="solid" onClick={() => props.togglePersonalOverview!()}>
                  <IonIcon icon={closeOutline} size="large" color="secondary" />
                </IonButton>
              </IonButtons>
            </IonCol>
            <IonCol size="auto" className={styles['onboarding-avatar-container']}>
              <IonAvatar>
                <IonImg alt={i18n.t('Employee profile picture for {name}', 'Employee profile picture for {name}', { name: user?.first_name })} src={profilePicture} />
              </IonAvatar>
              <p>{`${user?.first_name} ${user?.last_name}`}</p>
            </IonCol>
            <IonCol size="auto">
              <IonButtons>
                <IonButton style={{ 'background-color': 'none !important' }} size="large" shape="round" fill="clear" color='none' onClick={() => logout()}>
                  <IonIcon icon={logOutOutline} size="large" color="light" />
                </IonButton>
              </IonButtons>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonToolbar>
    </React.Fragment>
  );
};

export default PersonalOverviewToolbar;
