import React from 'react';
import { Cell, Legend, Pie, PieChart as PieChartRechart, Tooltip } from 'recharts';

import renderCustomizedLabel from './CustomLabels';
import type { Data, PieChartProps } from './Graphs';

const PieChart: React.FC<PieChartProps<Data>> = ({
  cellStyle,
  data,
  dataKey,
  height,
  nameKey,
  pieStyle,
  showLegend = true,
  width,
}) => {
  return (
    <PieChartRechart width={width || 200} height={height || 200}>
      <Pie
        data={data}
        cx="50%"
        cy="50%"

        labelLine={false}
        label={renderCustomizedLabel}
        outerRadius={75}
        fill="#8884d8"
        dataKey={'value' || dataKey}
        nameKey={'name' || nameKey}
        style={{ outline: 'none', ...pieStyle }}
      >
        {data.map((_, index) => (
          <Cell
            cursor={'pointer'}
            onClick={() => data[index].onClick?.()}
            key={`cell-${index}`}
            fill={data[index].color}
            style={{ outline: 'none', ...cellStyle, cursor: 'pointer' }}
          />
        ))}
      </Pie>
      <Tooltip labelStyle={{ fontSize: 15, }} />
      {showLegend &&
        <Legend
          layout="vertical"
          align="center"
          iconSize={20}
          style={{ cursor: 'pointer' }}
          wrapperStyle={{
            marginTop: '30px',
          }}
          onClick={(data: any | undefined, index, event) => {
            data.payload?.onClick(index, event);
          }}
        />
      }
    </PieChartRechart>
  );
};

export default PieChart;
