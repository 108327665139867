import type { E2U } from '@techlove/easy2use-typings';
import React from 'react';

import { useTableStyles } from '../../../../../components/UI/Table';

const ActivityCodeColumn: React.FC<{
  value: any;
  attributes: E2U.V1.Models.PrecalculationRow;
}> = (props) => {
  const styles = useTableStyles();
  return (
    <div className={styles.activity_code_column}>
      <div className={styles.activity_code_column__title}>{props.attributes.activity_code?.name}</div>
      <div className={styles.activity_code_column__description}>{`${props.attributes?.stamped_activity_code} - ${props.attributes.name}`} </div>
    </div>
  );
};

export default ActivityCodeColumn;
