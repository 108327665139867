
import { IonCol, IonGrid, IonItem, IonLabel, IonRow } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import classNames from 'classnames';
import { add, close } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import weStyles from './WorkingEnvironment.module.scss';
import { networking } from '../../../api/networking';
import PaginateData from '../../../components/Pagination/PaginationData';
import SearchBar from '../../../components/Search/SearchBar';
import SkeletonTextThreeLines from '../../../components/SkeletonComponents/SkeletonTextThreeLines';
import StepButtons from '../../../components/Toolbar/StepButtons';
import { handleEmptyListStates } from '../../../components/UI/EmptyList';
import NoSearchResults from '../../../components/UI/SearchAndSort/NoResults';
import { emptyListMessages } from '../../../constants/messages';
import { useAppSelector } from '../../../hooks';
import i18n from '../../../i18n';
import { setIsLoading } from '../../../reducers/loading';
import store from '../../../store';
import { scrollToSection } from '../../../tools/scrollToSection';

interface WorkingEnvironmentListProps {
  closeModal: () => void;
  updateEnvironment: () => void;
  environment: E2U.V1.Models.WorkingEnvironment | undefined;
  selectedItems: E2U.V1.Models.WorkingEnvironmentItem[] | undefined | any;
}
type EnvironmentResponse = E2U.V1.Response.Success<E2U.V1.Objects.PaginatedData<E2U.V1.Models.WorkingEnvironmentCategory>>;
const WorkingEnviromentList: React.FC<WorkingEnvironmentListProps> = ({
  closeModal,
  environment,
  selectedItems,
  updateEnvironment
}) => {
  const history = useHistory();
  const [items, setItems] = useState<E2U.V1.Models.WorkingEnvironmentCategory[]>([]);
  const [findSelection, setFindSelection] = useState<Record<string, boolean>>({});
  const [search, setSearch] = useState<string>('');
  const [totalPages, setTotalPages] = useState<number>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [error, setError] = useState<string | undefined>();

  const isLoading = useAppSelector(state => state.loading.isLoading.workingEnvironmentList);

  const scrollToId = 'working-environment-list-top';
  const searchParams = new URLSearchParams();

  searchParams.append('with[]', 'items');
  searchParams.append('search', search);
  searchParams.append('page', currentPage.toString());
  searchParams.append('per_page', '10');

  const fetchData = (page?: number) => {
    store.dispatch(setIsLoading({ name: 'workingEnvironmentList', value: true }));
    networking.get(`/api/v1/working_environment_categories?with=items&search=${search}&page=${typeof page !== 'undefined' ? page : currentPage}&per_page=10`)
      .then((response: EnvironmentResponse) => {
        setItems(response.data.data.records);
        setTotalPages(response.data.data.total_pages);
        setCurrentPage(response.data.data.current_page);
      })
      .catch((error) => {
        Sentry.captureMessage(`Error in WorkingEnvironmentList.tsx: ${error}`);
        setError(error.message);
      })
      .finally(() => {
        store.dispatch(setIsLoading({
          name: 'workingEnvironmentList',
          value: false
        }));
      });
  };

  const postSelection = (data: any) => {
    networking.post(`/api/v1/working_environments/${environment?.id}/sync`, {
      item_ids: Object.keys(data)
        .filter((key) => data[key])
    })
      .then(() => {
        updateEnvironment();
      })
      .finally(() => {
        closeModal();
      });
  };

  const handleSelectedItemsChanged = () => {
    if (typeof selectedItems !== 'undefined') {
      const newFindSelection: Record<string, boolean> = {};

      Object.values(selectedItems).forEach((entriesArr: any) => {
        entriesArr.forEach((entry: any) => {
          newFindSelection[entry.item_id] = true;
        });
      });
      setFindSelection(newFindSelection);
    }
  };

  const toggleSelectedItem = (item: string) => {
    setFindSelection({ ...findSelection, [item]: !findSelection[item] });
  };

  const pageStepper = (step: number) => {
    setCurrentPage(currentPage + step);
    scrollToSection(scrollToId);
  };

  useEffect(() => {
    handleSelectedItemsChanged();
  }, []);

  useEffect(() => {
    handleSelectedItemsChanged();
  }, [selectedItems]);

  useEffect(() => {
    fetchData(1);
    history.push({
      search: searchParams.toString(),
    });
  }, [search]);

  useEffect(() => {
    fetchData();
    setCurrentPage(currentPage);
    history.push({
      search: searchParams.toString(),
    });
  }, [currentPage]);

  useEffect(() => {
    fetchData();
  }, []);

  const messages = {
    empty: {
      title: emptyListMessages.working_environment_list.empty_title,
      message: emptyListMessages.working_environment_list.empty_message,
    },
    error: {
      message: '',
    },
  };

  return (
    <React.Fragment>
      <div id={scrollToId} />

      <SearchBar onSearch={setSearch} />
      {handleEmptyListStates({ isEmpty: !items.length, error, messages })}

      {isLoading
        ? <SkeletonTextThreeLines amount={5} />
        : (
          <React.Fragment>
            {
              items.map((item) => {
                return (
                  <React.Fragment key={item.id}>
                    <IonItem
                      lines='full'
                      color={'light'}
                      className='ion-margin-none ion-margin-top ion-margin-bottom'
                    >
                      <IonLabel
                        className='ion-no-margin ion-margin-end'
                        color={'dark'}>
                        {`${item.code} ${item.name}`}
                      </IonLabel>
                    </IonItem>

                    <IonGrid className={classNames(weStyles['add-item-container'], 'ion-no-padding')}>
                      {item.items.map((innerItems: E2U.V1.Models.WorkingEnvironmentItem, idx) => {
                        const isSelected = findSelection
                          ? (innerItems.id && findSelection[innerItems.id])
                          : false;
                        return (
                          <IonRow key={idx}>
                            <IonCol>
                              <IonItem
                                onClick={() => innerItems.id && toggleSelectedItem(innerItems.id)}
                                button detail={true}
                                detailIcon={
                                  findSelection.id
                                    ? close
                                    : add
                                }
                                className={classNames(
                                  'ion-margin-top ion-no-margin',
                                  weStyles[
                                    isSelected
                                      ? 'list-item-toggled'
                                      : 'list-item-untoggled'
                                  ]
                                )}
                                lines='none'>
                                {innerItems.name}
                              </IonItem>
                            </IonCol>
                          </IonRow>
                        );
                      })}
                    </IonGrid>
                  </React.Fragment>
                );
              })
            }
          </React.Fragment>
        )
      }
      <React.Fragment>
        {items.length > 0
          ? (
            <React.Fragment>
              <div className='ion-padding'>
                {items.length > 0 &&
                  <PaginateData
                    currentPage={currentPage}
                    totalPages={totalPages}
                    pageStepper={pageStepper}
                  />
                }
              </div>
              <div>
                <StepButtons
                  rightDisabled={
                    !findSelection
                      ? true
                      : Object.values(findSelection).every((value) => !value)
                  }
                  leftIonColor='none'
                  leftTitle={i18n.t('cancel')}
                  leftOnClick={() => closeModal()}
                  rightSubmit={'submit'}
                  rightTitle={i18n.t('save')}
                  rightIonColor={'secondary'}
                  rightOnClick={() => postSelection(findSelection)}
                />
              </div>
            </React.Fragment>
          )
          : isLoading
            ? <React.Fragment />
            : <NoSearchResults />
        }
      </React.Fragment>
    </React.Fragment>
  );
};
export default WorkingEnviromentList;
