import {
  createSlice
} from '@reduxjs/toolkit';
import type {
  Draft,
  PayloadAction,
  SliceCaseReducers
} from '@reduxjs/toolkit';

export interface NavigationReducer {
  currentTab: string | undefined;
  submenuLocked: boolean;
}

export const navigationSlice = createSlice<NavigationReducer,
  SliceCaseReducers<NavigationReducer>,
  string>({
    name: 'navigation',
    initialState: {
      currentTab: undefined,
      submenuLocked: false
    },
    reducers: {
      setCurrentTab: (
        state: Draft<NavigationReducer>,
        action: PayloadAction<string>
      ) => {
        state.currentTab = action.payload;
      },
      setSubmenuLocked: (
        state: Draft<NavigationReducer>,
        action: PayloadAction<boolean>
      ) => {
        state.submenuLocked = action.payload;
      }
    }
  });

export const {
  setCurrentTab,
  setSubmenuLocked
} = navigationSlice.actions;

export default navigationSlice.reducer;
