import { IonButton, IonButtons, IonCol, IonContent, IonGrid, IonIcon, IonModal, IonRow, IonToolbar } from '@ionic/react';
import { close } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';

import BigUp from '..';
import styles from './InputModal.module.scss';
import type { InputModalProps } from './interface';

const InputModal: React.FC<InputModalProps> = ({ button, input, modal, onSubmit }) => {
  const { t } = useTranslation();
  return (
    <IonModal
      isOpen={modal.isOpen}
      onIonModalDidDismiss={modal.onDismiss}
      className={styles['input-modal']}>
      <IonToolbar color={'light'}>
        <IonButtons slot='end'>
          <IonButton onClick={modal.onDismiss}>
            <IonIcon slot="icon-only" icon={close} />
          </IonButton>
        </IonButtons>
      </IonToolbar>
      <IonContent scrollY={false}>
        <form onSubmit={onSubmit}>

          <IonGrid>
            <IonRow className='ion-justify-content-center ion-no-padding'>
              <IonCol size='auto' className='ion-no-padding'>
                <BigUp.Label.V2Thick label={modal.title} />
              </IonCol>
            </IonRow>
            <IonRow className='ion-justify-content-center'>
              <IonCol size='10' className=' ion-text-center'>
                <BigUp.Typography.Description>
                  {modal.description}
                </BigUp.Typography.Description>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className='ion-padding'>
                <BigUp.OutlinedInput {...input} />
              </IonCol>
            </IonRow>
            <IonRow className='ion-justify-content-center'>
              <IonCol size='auto'>
                <BigUp.Buttons.Primary
                  onClick={button.onClick}
                  {...button.type !== undefined && {
                    type: 'submit'
                  }}
                  title={button.title || t('Save')}
                  disabled={button.disabled}
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        </form>
      </IonContent>
    </IonModal>
  );
};
export default InputModal;
