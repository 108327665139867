import { IonCol, IonGrid, IonIcon, IonRow, IonSearchbar } from '@ionic/react';
import { chevronDownOutline, chevronUpOutline, filterOutline } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';

import styles from './Table.module.scss';
import BigUp from '../';
import type { DataItemProps, FilterProps, TableColumnProps, TableProps } from './interfaces';
import { networking } from '../../../api/networking';
import i18n from '../../../i18n';

const TableColumn: React.FC<TableColumnProps> = (props) => {
  const classes = [
    styles[`text__${props.alignment ?? 'center'}`],
  ];

  if (props.className) {
    classes.push(props.className);
  }

  return (
    <IonCol onClick={props.onClick}
      className={classes.join(' ')}
      size={props?.size || null}
      sizeLg={props.sizes?.lg || null}
      sizeMd={props.sizes?.md || null}
      sizeSm={props.sizes?.sm || null}
      sizeXl={props.sizes?.xl || null}
      sizeXs={props.sizes?.xs || null}>
      {props.children}
      {props.actions}
    </IonCol>
  );
};

const Table: React.FC<TableProps> = (props) => {
  const { columns, filters, onRefetch, reducers, shouldRefetch, sourceUrl } = props;

  const [rows, setRows] = useState(props.rows ?? []);

  const [sortColumn, setSortColumn] = useState(0);
  const [sortDirection, setSortDirection] = useState('asc');

  const tableHead = useRef<HTMLIonRowElement>(null);
  const tableBody = useRef<HTMLDivElement>(null);
  const [tableScrollYPosition, setTableScrollYPosition] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');

  const fetchRows = (url: string) => {
    const urlSearchParams = new URLSearchParams(url);
    urlSearchParams.set('search', searchQuery);
    urlSearchParams.set('sort_by', columns[sortColumn].key);
    urlSearchParams.set('direction', sortDirection);
    networking.get(`${url}?${urlSearchParams.toString()}`)
      .then((response) => {
        setRows(response.data.data.records);
      })
      .catch((e) => {
        console.error('[Table] Error fetching source data: ', e);
      });
  };

  const runSearch = (query: string) => {
    setSearchQuery(query);
  };

  const runSort = (i: number) => {
    setSortColumn(i);
  };

  const handleBodyScroll = () => {
    if (tableBody.current) {
      const { scrollLeft } = tableBody.current;
      setTableScrollYPosition(scrollLeft || 0);
    }
  };

  useEffect(() => {
    if (sourceUrl) {
      fetchRows(sourceUrl);
    }
  }, []);

  useEffect(() => {
    if (tableHead.current) {
      tableHead.current.scrollLeft = tableScrollYPosition;
    }
  }, [tableScrollYPosition]);

  useEffect(() => {
    if (shouldRefetch) {
      if (!sourceUrl) {
        return;
      }
      fetchRows(sourceUrl);
      if (onRefetch) {
        onRefetch();
      }
    }
  }, [shouldRefetch]);

  useEffect(() => {
    if (!sourceUrl) {
      return;
    }
    fetchRows(sourceUrl);
  }, [sortColumn, sortDirection, searchQuery]);

  return (
    <IonGrid>
      <IonRow className={styles.wrapper}>
        <IonCol className={styles.container}>
          <div className={styles.header}>
            <IonRow>
              <IonCol sizeMd={filters ? '11' : '12'}
                sizeXs={filters ? '10' : '12'}
                className={styles.header__search}>
                <IonSearchbar onIonInput={(e) => runSearch(e.detail.value as string)}
                  animated={true}
                  placeholder={i18n.t('Search')}
                  className={styles.header__search_box}></IonSearchbar>
              </IonCol>
              {filters && (
                <IonCol sizeMd='1'
                  sizeXs='2'
                  className={styles.header__filters}>
                  <IonIcon id='display-filter-actions'
                    icon={filterOutline}
                    className={styles.header__filters_icon}></IonIcon>
                  <BigUp.Popovers.Default items={filters.map((filter: FilterProps) => {
                    return {
                      ...filter,
                      value: filter.label,
                      onClick: () => filter.callback(rows, setRows),
                    };
                  })}
                  actionId="display-filter-actions" />
                </IonCol>
              )}
            </IonRow>
            <IonRow ref={tableHead} className={styles.header__headings}>
              {columns.map((column: DataItemProps, i) => {
                const { key, label, ...rest } = column;

                return (
                  <TableColumn key={i}
                    onClick={() => runSort(i)}
                    className={styles.header__headings_column} {...rest}>
                    {label}
                    <IonIcon
                      icon={(sortColumn === i && sortDirection === 'desc') ? chevronUpOutline : chevronDownOutline}
                      className={styles.header__headings_actions_sort} />
                  </TableColumn>
                );
              })}
            </IonRow>
          </div>

          <div className={styles.body}>
            <div id="body"
              ref={tableBody}
              onScroll={() => handleBodyScroll()}
              className={styles.body__values}
              style={{
                maxHeight: rows.length ? (props.height || '535px') : 'auto',
              }}>
              {rows.length
                ? rows.map((row, a) => {
                  const { onRowClick } = props.callbacks ?? {};
                  const classes = [styles.body__values_row];

                  if ((a % 2 === 0)) {
                    classes.push(styles.body__values_oddrow);
                  }

                  return (
                    <IonRow key={a}
                      onClick={() => onRowClick ? onRowClick(row) : null}
                      className={classes.join(' ')}>
                      {columns.map((column: DataItemProps, b) => {
                        const { key, ...rest } = column;

                        let body = (row[key] ?? key);

                        if (column.body) {
                          if (typeof column.body === 'string') {
                            body = React.createElement(column.body, {
                              value: row[key] ?? key,
                              attributes: row,
                            });
                          } else {
                            body = React.cloneElement(column.body, {
                              value: row[key] ?? key,
                              attributes: row,
                            });
                          }
                        } else {
                          if (typeof reducers !== 'undefined' && reducers[key]) {
                            body = reducers[key](row[key]);
                          }
                        }

                        return (
                          <TableColumn key={b}
                            className={styles.body__values_column} {...rest}>
                            {body}
                          </TableColumn>
                        );
                      })}
                    </IonRow>
                  );
                })
                : (
                  <IonRow className={styles.body__values_empty}>
                    <IonCol className={styles.body__values_empty_text}>
                      {i18n.t('No data to display')}
                    </IonCol>
                  </IonRow>
                )}
            </div>
          </div>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export const useTableStyles = () => {
  return styles;
};

export default Table;
