import { IonCol, IonRow, IonSelect, IonSelectOption } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { useFormContext } from 'react-hook-form';

import BigUp from '../../../../components/UI';
import { useAppSelector } from '../../../../hooks';
import i18n from '../../../../i18n';
import { setSelectedPrecalculation } from '../../../../reducers/precalculations';
import store from '../../../../store';

interface RegenerationFormProps {
  userPrecalculations: E2U.V1.Models.Precalculation[];
  projectPrecalculations: E2U.V1.Models.Precalculation[];
  openModal: () => void;
  lockBudget: () => void;
  isLocked: boolean;
}

const RegenerationForm: React.FC<RegenerationFormProps> = ({
  isLocked,
  lockBudget,
  openModal,
  projectPrecalculations,
  userPrecalculations,
}) => {
  const methods = useFormContext();
  const isGeneratingBudget = useAppSelector((state) => state.loading.isLoading.generatingProductionBudget);
  const combinedPrecalculations = [...userPrecalculations, ...projectPrecalculations];
  const selectedBudget = useAppSelector((state) => state.productionBudget.productionBudget);
  return (
    <IonRow className='ion-justify-content-between ion-align-items-center'>
      <IonCol sizeMd='5' size={'7'}>
        <IonSelect
          onIonChange={(e) => {
            methods.setValue('precalculation_id', e.detail.value);
            methods.trigger('precalculation_id');
            store.dispatch(setSelectedPrecalculation(e.detail.value));
          }}
          label={i18n.t('Precalculation to generate from')}
          labelPlacement='stacked'
          placeholder={i18n.t('Select precalculation')}
          {...methods.register('precalculation_id', { required: true })}
        >
          {combinedPrecalculations.map((precalculation, index) => (
            <IonSelectOption
              key={index}
              value={precalculation.id}>
              {precalculation.name}
            </IonSelectOption>
          ))}
        </IonSelect>
      </IonCol>
      <IonCol sizeMd='6' size='4' className='ion-text-md-start ion-text-sm-end'>
        <BigUp.Buttons.Secondary
          size='small'
          title={i18n.t('Generate')}
          disabled={isGeneratingBudget || !methods.formState.isValid}
          {...selectedBudget?.rows?.length !== 0 && { type: 'submit' }}
          onClick={() => {
            openModal();
          }}
        />
      </IonCol>
    </IonRow>

  );
};

export default RegenerationForm;
