import ActionSheet from './ActionSheets';
import MediaUpload from './ActionSheets/MediaUpload';
import Buttons from './Buttons/Buttons';
import MediaUploader from './Buttons/MediaUploader';
import Checkbox from './Checkboxes/Checkbox';
import DatepickerOutlined from './Datepickers/DatepickerOutlined';
import DateTimeContent from './Dates/DatetimeContent';
import RadialChart from './Graphs/RadialChart';
import Header from './Header/Header';
import HeaderSimple from './Header/HeaderSimple';
import ColorPicker from './Inputs/Colorpicker';
import Input from './Inputs/Input';
import OutlinedInput from './Inputs/InputOutlined';
import MetadataItem from './Items/MetadataItems/MetadataItem';
import Label from './Labels/Label';
import ItemList from './List/ItemList';
import BottomSheet from './Modal/BottomSheet';
import InputModal from './Modal/InputModal';
import Controls from './Pannable/Controls/Controls';
import Popover from './Popovers';
import { ProjectSelectionPopover } from './Popovers/ProjectSelectionPopover';
import ReorderableList from './Reorder/Reorder';
import CountrySelect from './Select/CountrySelect';
import Select from './Select/Select';
import Table from './Table';
import TabBar from './Tabs/TabBar/TabBar';
import Textarea from './Textareas/Textarea';
import Title from './Titles/Title';
import toasters from './Toasts';
import Description from './Typography/Description';
import { Calculation } from '../icons/tools/Calculation';
import CalculationIcon from '../icons/tools/calculation.svg';
import { Cashflow } from '../icons/tools/Cashflow';
import CashflowIcon from '../icons/tools/cashflow.svg';
import { Changes } from '../icons/tools/Changes';
import ChangesIcon from '../icons/tools/changes.svg';
import ControlOfExecutionIcon from '../icons/tools/control-of-execution.svg';
import { ControlOfExecution } from '../icons/tools/ControlOfExecution';
import { Deviations } from '../icons/tools/Deviations';
import DeviationsIcon from '../icons/tools/deviations.svg';
import { Document } from '../icons/tools/Document';
import DocumentIcon from '../icons/tools/document.svg';
import DocumentsPrimaryIcon from '../icons/tools/documents-primary.svg';
import FavouritesIcon from '../icons/tools/favourites.svg';
import HomeIcon from '../icons/tools/home.svg';
import { Map } from '../icons/tools/Map';
import MapIcon from '../icons/tools/map.svg';
import PaymentScheduleIcon from '../icons/tools/payment-schedule.svg';
import { PaymentSchedule } from '../icons/tools/PaymentSchedule';
import ProductionBudgetIcon from '../icons/tools/production-budget.svg';
import { ProductionBudget } from '../icons/tools/ProductionBudget';
import { Prognosis } from '../icons/tools/Prognosis';
import PrognosisIcon from '../icons/tools/prognosis.svg';
import ProjectInformationIcon from '../icons/tools/project-information.svg';
import { ProjectInformation } from '../icons/tools/ProjectInformation';
import SiteAccessRequestIcon from '../icons/tools/site-access-requests.svg';
import { SiteAccessRequest } from '../icons/tools/SiteAccessRequests';
import DarkmodeToggle from '../Toggle/DarkmodeToggle';
import FileItem from './Items/FileItem';
import FilePreviewModal from './modals/FilePreviewModal';
import SelectOutlined from './Select/SelectOutlined';
import Pdf from '../icons/Pdf';
import Png from '../icons/Png';
import ProjectsIcon from '../icons/tools/projects.svg';
import { Reports } from '../icons/tools/Reports';
import ReportsIcon from '../icons/tools/reports.svg';
import { Teams } from '../icons/tools/Teams';
import TeamsIcon from '../icons/tools/teams.svg';
import ToolsIcon from '../icons/tools/tools.svg';
import WorkPreparationIcon from '../icons/tools/work-preparation.svg';
import WorkingEnvironmentIcon from '../icons/tools/working-environment.svg';
import WorkingEnvironment from '../icons/tools/WorkingEnvironment';
import { WorkPreparation } from '../icons/tools/WorkPreparation';
import ButtonContainer from './Widgets/Containers/ButtonContainer';
import Logo from '../../assets/svg/bigup-white.svg';

export const BigUp = {
  Widget: {
    ButtonContainer
  },
  Graphs: {
    RadialChart,
  },
  Typography: {
    Description
  },
  Icons: {
    Pdf,
    Png,
    WorkingEnvironment,
    ControlOfExecution,
    Calculation,
    Document,
    Teams,
    WorkPreparation,
    Map,
    SiteAccessRequest,
    ProjectInformation,
    PaymentSchedule,
    ProductionBudget,
    Reports,
    Cashflow,
    Prognosis,
    Deviations,
    Changes,
    Svg: {
      WorkingEnvironment: WorkingEnvironmentIcon,
      ControlOfExecution: ControlOfExecutionIcon,
      Calculation: CalculationIcon,
      Document: DocumentIcon,
      Teams: TeamsIcon,
      WorkPreparation: WorkPreparationIcon,
      Map: MapIcon,
      SiteAccessRequest: SiteAccessRequestIcon,
      ProjectInformation: ProjectInformationIcon,
      PaymentSchedule: PaymentScheduleIcon,
      ProductionBudget: ProductionBudgetIcon,
      Reports: ReportsIcon,
      Cashflow: CashflowIcon,
      Prognosis: PrognosisIcon,
      Deviations: DeviationsIcon,
      Changes: ChangesIcon,
      Projects: ProjectsIcon,
      DocumentPrimary: DocumentsPrimaryIcon,
      Favourites: FavouritesIcon,
      Home: HomeIcon,
      Tools: ToolsIcon
    }
  },
  Modal: {
    FilePreviewModal,
    InputModal
  },
  Datepicker: {
    DatepickerOutlined
  },
  Date: {
    DateTimeContent
  },
  ItemList,
  Input,
  toasters,
  Textarea,
  Select,
  SelectOutlined,
  Checkbox,
  DarkmodeToggle,
  ReorderableList,
  Label,
  Buttons,
  CountrySelect,
  MetadataItem,
  Title,
  Table,
  Tabs: {
    TabBar,
  },
  Pannable: {
    Controls
  },
  Item: {
    FileItem
  },
  MediaUploader,
  BottomSheet,
  ColorPicker,
  Header,
  Popovers: {
    Default: Popover,
    ProjectSelectionPopover
  },
  ActionSheets: {
    ActionSheet,
    MediaUpload
  },
  OutlinedInput,
  HeaderSimple,
  Logo,
};

export default BigUp;
