import { IonContent, IonIcon, IonItem, IonLabel, IonList } from '@ionic/react';
import type { ComponentProps } from 'react';
import React from 'react';

import styles from './Popover.module.scss';

export interface PopoverItem {
  value: string;
  label: string;
  onClick: () => void;
  icon?: ComponentProps<typeof IonIcon>;

}
interface PopoverHandling {
  items: PopoverItem[];
  actionId: string;
}

const Popover: React.FC<PopoverHandling> = (props) => {
  return (
    <IonContent scrollY className={styles['popover-element']}>
      <IonList>
        {props.items.map((item) => (
          <IonItem key={item.label} button={true} detail={false} onClick={item.onClick} className='ion-no-padding'>
            {item.icon && <IonIcon icon={item.icon?.icon} className={styles['popover-item-icon']} />} <IonLabel className='ion-no-margin ion-padding-vertical'>
              {item.label}
            </IonLabel>
          </IonItem>
        ))}
      </IonList>
    </IonContent>
  );
};

export default Popover;
