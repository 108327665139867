import { IonCol, IonDatetime, IonGrid, IonList, IonModal, IonRow, IonSelectOption, IonSpinner } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import classnames from 'classnames';
import { arrowDownOutline, arrowUpOutline, checkmarkCircleSharp } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import type { FieldValues, SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import styles from './ProductionBudget.module.scss';
import ProductionBudgetRow from './ProductionBudgetRow';
import RegenerationForm from './RegenerationForm';
import { networking } from '../../../../api/networking';
import AlarmModalContent from '../../../../components/Alarms/AlarmModalContent';
import PaginateData from '../../../../components/Pagination/PaginationData';
import toasters from '../../../../components/Toasts/Toasts';
import BigUp from '../../../../components/UI';
import SearchAndSortRow from '../../../../components/UI/SearchAndSort/SearchAndSortRow';
import { useAppSelector } from '../../../../hooks';
import useModal from '../../../../hooks/useModal';
import { setIsLoading } from '../../../../reducers/loading';
import { setSelectedPrecalculation } from '../../../../reducers/precalculations';
import { setProductionBudget } from '../../../../reducers/production-budget';
import { softUnlock } from '../../../../reducers/subpanelLock';
import store from '../../../../store';
import { buildOptionalQuery } from '../../../../tools/queryBuilder';
import modalStyle from '../../../Onboarding/Components/containers/styles/ModalContainers.module.scss';
import { PaginationLabel } from '../Precalculations/PreCalculationRows';

interface Lock<T> {
  isLocked: boolean | T;
  fetchProject: () => void;
}

const ProductionBudget: React.FC<Lock<boolean>> = (props: Lock<boolean>) => {
  const { t } = useTranslation();

  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState('created_at');
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState<number>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [productionBudgetRows, setProductionBudgetRows] = useState<E2U.V1.Models.ProductionBudgetRow[]>([]);
  const [error, setError] = useState<string | undefined>();
  const [projectPrecalculations, setProjectPrecalculations] = useState<E2U.V1.Models.Precalculation[]>([]);
  const [userPrecalculations, setUserPrecalculations] = useState<E2U.V1.Models.Precalculation[]>([]);
  const { closeModal, isModalOpen, openModal } = useModal();
  const { handleSubmit } = useForm<FieldValues>({ mode: 'onChange', reValidateMode: 'onChange' });
  const { uuid } = useParams<{ uuid: string | undefined }>();

  const closeAlarmingModal = () => {
    closeModal();
    store.dispatch(setSelectedPrecalculation(undefined));
  };

  const generateMethods = useForm<{
    precalculation_id: string;
  }>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      precalculation_id: undefined,
    }
  });

  const productionBudget: E2U.V1.Models.ProductionBudget | undefined | any =
    useAppSelector((state) => state.productionBudget.productionBudget);
  const isLoadingProductionBudget = useAppSelector((state) => state.loading.isLoading.productionBudget);
  const isLoadingUserPrecalculations = useAppSelector((state) => state.loading.isLoading.userPrecalculations);
  const isLoadingProjectPrecalculations = useAppSelector((state) => state.loading.isLoading.projectPrecalculations);
  const isLocked = useAppSelector(state => state.lockSubpanels.isLocked.productionBudgets);
  const selectedProject = useAppSelector((state) => state.project.selectedProject);
  const isDesktop = useAppSelector((state) => state.desktopView.isDesktop);
  const currentUser = useAppSelector(state => state.authentication.user);

  const fetchProductionBudget = async (): Promise<any> => {
    store.dispatch(setIsLoading({ name: 'productionBudget', value: true }));
    try {
      const response = await toasters.promise(
        networking.get(`/api/v1/projects/${uuid}/production_budget?with=lockedBy`),
        { error: t("Couldn't load production budget rows") }
      );

      store.dispatch(setProductionBudget(response.data.data.records[0]));
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      store.dispatch(setIsLoading({ name: 'productionBudget', value: false }));
    }
  };

  const fetchRows = () => {
    networking.get(`/api/v1/production_budgets/${productionBudget.id}/rows?${buildOptionalQuery(search, sortBy, currentPage, perPage)}`)
      .then((response: E2U.V1.Response.Success<E2U.V1.Objects.PaginatedData<E2U.V1.Models.ProductionBudgetRow>>) => {
        setProductionBudgetRows(response.data.data.records);
        setTotalPages(response.data.data.total_pages);
        setCurrentPage(response.data.data.current_page);
      })
      .catch((error) => {
        Sentry.captureException(error);
        setError(error.message);
      });
  };

  const toastersLockProductionBudget = {
    pending: t('Locking production budget...'),
    success: t('Production budget is locked'),
    error: t("Couldn't lock production budget")
  };
  const selectedPrecalculation = useAppSelector(state => state.precalculations.selectedPrecalculation);

  const lockProductionBudget: SubmitHandler<FieldValues> = () => {
    toasters
      .promise(networking.post(`/api/v1/production_budgets/${productionBudget?.id}/lock`, {
        lock_date: new Date().toISOString(),
      }), { ...toastersLockProductionBudget, })
      .then(() => {
        fetchProductionBudget();
        handleGenerateBudget({ precalculation_id: selectedPrecalculation });
      })
      .catch((error: E2U.V1.Response.Error) => {
        Sentry.captureException(error);
      })
      .finally(() => {
        onLockDate();
      });
  };

  const onLockDate = () => {
    closeModal();
    store.dispatch(softUnlock(false));
    store.dispatch(setSelectedPrecalculation(undefined));
  };

  const pageStepper = (step: number) => {
    setCurrentPage(currentPage + step);
  };

  const numberOfResults = (resultPerPage: number, startPage: number) => {
    setPerPage(resultPerPage);
    setCurrentPage(startPage);
    setSearch('');
  };

  const fetchProjectPrecalculations = () => {
    setProjectPrecalculations([]);
    store.dispatch(setIsLoading({ name: 'projectPrecalculations', value: true }));
    networking.get(`/api/v1/projects/${uuid}/precalculations`)
      .then((response: E2U.V1.Response.Success<E2U.V1.Objects.PaginatedData<E2U.V1.Models.Precalculation>>) => {
        setProjectPrecalculations(response.data.data.records);
      })
      .catch((error) => {
        Sentry.captureException(error);
      })
      .finally(() => {
        store.dispatch(setIsLoading({ name: 'projectPrecalculations', value: false }));
      });
  };

  const fetchUserPrecalculations = () => {
    setUserPrecalculations([]);
    store.dispatch(setIsLoading({ name: 'userPrecalculations', value: true }));
    networking.get(`/api/v1/users/${currentUser?.id}/precalculations?per_page=9999`)
      .then(
        (response: E2U.V1.Response.Success<E2U.V1.Objects.PaginatedData<E2U.V1.Models.Precalculation>>) => {
          setUserPrecalculations(response.data.data.records);
        })
      .catch((error) => {
        Sentry.captureException(error);
      })
      .finally(() => {
        store.dispatch(setIsLoading({ name: 'userPrecalculations', value: false }));
      });
  };

  const handleGenerateBudget: SubmitHandler<FieldValues> = (data) => {
    store.dispatch(setIsLoading({ name: 'generatingProductionBudget', value: true }));
    networking.post(`/api/v1/production_budgets/${productionBudget?.id}/${data.precalculation_id}/regenerate`)
      .then(
        (response: E2U.V1.Response.Success<E2U.V1.Models.ProductionBudget>) => {
          setProductionBudget(response.data.data);
          generateMethods.reset();
          fetchRows();
        }
      ).catch((error) => {
        Sentry.captureException(error);
      }).finally(() => {
        store.dispatch(setIsLoading({ name: 'generatingProductionBudget', value: false }));
      });
  };

  useEffect(() => {
    fetchProjectPrecalculations();
    fetchUserPrecalculations();
    fetchProductionBudget();
  }, [uuid]);

  useEffect(() => {
    if (productionBudget?.id) {
      fetchRows();
    }
  }, [uuid, search, sortBy, currentPage, perPage, productionBudget?.id, productionBudget?.locked_by]);

  return (isLoadingProductionBudget || isLoadingProjectPrecalculations || isLoadingUserPrecalculations)
    ? <IonSpinner />
    : <React.Fragment>
      <div className={'ion-padding'}>
        <div className={styles['production-budget-page-container']}>
          <div className={classnames(styles['production-budget-container'])} slot="content">
            <div className={styles['production-row-container']} style={{ width: '100%' }}>
              <React.Fragment>
                <IonGrid>
                  <IonRow className='ion-margin-bottom  ion-align-items-center ion-justify-content-start'>
                    <IonCol size='auto' className='ion-padding-end ion-margin-end'>
                      <BigUp.Title label={t('Production budget')} />
                    </IonCol>
                    {productionBudget?.locked_by && (
                      <>
                        <IonCol sizeXs='12' sizeSm='8' sizeMd='auto'>
                          <BigUp.Label.Indicator
                            indicator={{
                              color: 'var(--ion-color-success)',
                              shape: 'circle',
                            }}
                            label={{
                              gap: '5px',
                              start: { label: t('Locked by') },
                              end: { label: `${productionBudget?.locked_by?.first_name} ${productionBudget?.locked_by?.last_name}` },
                            }}
                          />
                        </IonCol>
                        <IonCol sizeXs='12' sizeSm='8' sizeMd='auto' >
                          <BigUp.Label.Indicator
                            indicator={{
                              color: 'var(--ion-color-danger)',
                              shape: 'circle',
                            }}
                            label={{
                              gap: '5px',
                              start: { label: t('Locked at') },
                              end: { label: productionBudget?.lock_date },
                            }}
                          />
                        </IonCol>
                      </>
                    )}
                  </IonRow>
                  <FormProvider {...generateMethods}>
                    <form onSubmit={generateMethods.handleSubmit(handleGenerateBudget)}>
                      <RegenerationForm
                        openModal={() => openModal()}
                        lockBudget={() => lockProductionBudget(productionBudget.id)}
                        isLocked={isLocked}
                        userPrecalculations={userPrecalculations}
                        projectPrecalculations={projectPrecalculations}
                      />
                    </form>
                  </FormProvider>

                  <IonRow className={'ion-align-items-center'}>
                    <IonCol>
                      <SearchAndSortRow onSearch={setSearch} onSort={setSortBy} value={search}>
                        <IonSelectOption value={'name'}>{t('Name')}</IonSelectOption>
                        <IonSelectOption value={'created_at'}>{t('Created at')}</IonSelectOption>
                        <IonSelectOption value={'updated_at'}>{t('Last modified')}</IonSelectOption>
                      </SearchAndSortRow>
                    </IonCol>
                  </IonRow>
                  <IonRow className='ion-justify-content-end ion-align-items-center'>
                    <IonCol>
                      <PaginationLabel currentPage={currentPage} totalPages={totalPages} />
                    </IonCol>
                    {productionBudgetRows.length !== 0 && (
                      <IonCol size='5' className='ion-text-right ion-margin-bottom'>
                        <BigUp.Buttons.Regular
                          title={perPage >= 9999 ? t('Show less') : t('Show all')}
                          icon={perPage >= 9999 ? { icon: arrowUpOutline } : { icon: arrowDownOutline }}
                          onClick={() => numberOfResults(perPage >= 9999 ? 10 : 9999, perPage >= 9999 ? 1 : 0)}
                          color={'medium'}
                          fill='outline'
                          expand='block'
                        />
                      </IonCol>
                    )}
                  </IonRow>
                </IonGrid>
                {productionBudgetRows.length === 0 && (
                  <IonGrid>
                    <IonRow className='ion-align-items-center ion-justify-content-center'>
                      <IonCol size={'12'} className='ion-text-center'>
                        <BigUp.Label.Thick color='dark' label={t('Generate the production budget to get started.')} />
                      </IonCol>
                    </IonRow>
                    <IonRow className='ion-align-items-center ion-justify-content-center'>
                      <IonCol sizeSm={'8'} className='ion-text-center'>
                        <BigUp.Label.Regular
                          label={t('The production budget has not been generated. Select a precalculation below and press "Generate" to get started.')}
                          className='ion-no-margin ion-text-center'
                        />
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                )}
                <IonList {...isDesktop && {
                  className: 'ion-margin-end ion-padding-end'
                }}>
                  {productionBudgetRows?.map((row, i) => {
                    return (
                      <ProductionBudgetRow key={i} row={row} />
                    );
                  })}
                  <PaginateData
                    currentPage={currentPage}
                    totalPages={totalPages}
                    pageStepper={pageStepper}
                  />
                </IonList>
              </React.Fragment>
            </div>
          </div>

          <IonModal keepContentsMounted={true}>
            <IonDatetime
              showDefaultButtons={true}
              size="cover"
              id="datetime"
              className={styles.test}
            />
          </IonModal>

          <IonModal isOpen={isModalOpen} onIonModalDidDismiss={closeAlarmingModal} className={modalStyle['app-default-modal']}>
            <form onSubmit={handleSubmit(lockProductionBudget)} className={styles.projectLockForm}>
              {selectedProject?.locked &&
                <AlarmModalContent
                  modal={{ modalDismiss: closeAlarmingModal }}
                  colour={{
                    backgroundColour: 'var(--ion-color-danger)',
                    textColours: 'var(--ion-color-dark)'
                  }}
                  texts={{
                    alertTitle: t('Are you sure you want to re-lock the production budget?'),
                    alertSubtitle: `${selectedProject?.name}`,
                    contentText: t('Locking the production budget again will do the following:'),
                  }}
                  buttonLeft={{
                    leftTitle: t('Cancel'),
                    leftIonColour: 'medium',
                    leftOnClick: closeAlarmingModal
                  }}
                  buttonRight={{
                    shouldSubmit: true,
                    rightTitle: t('Lock'),
                    rightIonColour: 'none',
                  }}
                />
              }
              {!selectedProject?.locked &&
                <AlarmModalContent
                  modal={{ modalDismiss: closeAlarmingModal }}
                  alert={{ alertIcon: checkmarkCircleSharp }}
                  colour={{
                    backgroundColour: 'var(--ion-color-success)',
                    textColours: 'var(--ion-color-light)',
                    buttonRightColour: 'var(--ion-color-light)'
                  }}
                  texts={{
                    alertTitle: t('Are you sure you want to lock the production budget?'),
                    alertSubtitle: `${selectedProject?.name}`,
                    contentText: t('Locking the production budget will do the following:'),
                  }}
                  buttonLeft={{
                    leftTitle: t('Cancel'),
                    leftIonColour: 'none',
                    leftOnClick: closeModal
                  }}
                  buttonRight={{
                    shouldSubmit: true,
                    rightTitle: t('Unlock'),
                    rightIonColour: 'success',
                  }}
                />
              }
            </form>
          </IonModal>
        </div>
      </div>
    </React.Fragment>;
};
export default ProductionBudget;
