import { IonCol, IonGrid, IonLabel, IonModal, IonRow } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import AccessRequestDetails from './AccessRequestDetails';
import ItemShadow from '../../../components/UI/Items/components/ItemShadow';
import { ionicColours } from '../../../components/UI/variables';
import modalStyles from '../../Onboarding/Components/containers/styles/ModalContainers.module.scss';

interface SiteAccessInterface {
  request: E2U.V1.Models.ProjectAccessRequest;
  handleStatusUpdate: () => void;
}

const AccessRequestCard: React.FC<SiteAccessInterface> = ({ handleStatusUpdate, request }) => {
  const [showDetailed, setShowDetailed] = useState(false);

  const { t } = useTranslation();

  const toggleDetails = () => {
    if (showDetailed) {
      handleStatusUpdate();
    }
    setShowDetailed(!showDetailed);
  };

  let statusBorder = '';
  switch (request.status) {
    case 'rejected':
      statusBorder = ionicColours.danger;
      break;
    case 'pending':
      statusBorder = ionicColours.secondary;
      break;
    case 'approved':
      statusBorder = ionicColours.success;
      break;
    default:
      statusBorder = 'none';
  }

  return (
    <React.Fragment>
      <ItemShadow
        hasBorderLeft={true}
        borderLeftColour={`5px solid ${statusBorder}`}
        clickHandler={toggleDetails}
      >
        <IonGrid>
          <IonRow>
            <IonCol size='6' className='ion-padding'>
              <IonLabel className='ion-margin-bottom ion-no-margin'>
                <h3>{t('Firstname')}:</h3>
                <p>{request.personal_information?.first_name}</p>
              </IonLabel>
              <IonLabel className='ion-margin-bottom ion-no-margin'>
                <h3>{t('Lastname')}:</h3>
                <p>{request.personal_information?.last_name}</p>
              </IonLabel>
            </IonCol>
            <IonCol size='6' className='ion-padding'>
              <IonLabel className='ion-margin-bottom ion-no-margin'>
                <h3>{t('Company')}:</h3>
                <p>{request.personal_information?.company_name}</p>
              </IonLabel>
              <IonLabel className='ion-margin-bottom ion-no-margin'>
                <h3>{t('Met requirements')}:</h3> {request.completed_all_requirements
                  ? <p>{t('Yes')}</p>
                  : <p>{t('No')}</p>}
              </IonLabel>
            </IonCol>
          </IonRow>
        </IonGrid>
      </ItemShadow>

      <IonModal isOpen={showDetailed} onIonModalDidDismiss={() => setShowDetailed(false)} className={modalStyles['app-default-modal']}>
        <AccessRequestDetails details={request} detailToggle={toggleDetails} />
      </IonModal>
    </React.Fragment>
  );
};

export default AccessRequestCard;
