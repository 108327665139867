import { Geolocation } from '@capacitor/geolocation';
import type { Position } from '@capacitor/geolocation';
import {
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonRow,
  isPlatform, useIonAlert
} from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import classNames from 'classnames';
import { arrowBack, arrowForward, locationOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { networking } from '../../../../api/networking';
import BigUp from '../../../../components/UI';
import EmptyList from '../../../../components/UI/EmptyList';
import toasters from '../../../../components/UI/Toasts';
import { matchOnboarding } from '../../../../constants/onboarding';
import { useAppSelector } from '../../../../hooks';
import useAcl from '../../../../hooks/useAcl';
import useCustomModalAnimations from '../../../../hooks/useCustomModalAnimation';
import useFetchProjects from '../../../../hooks/useFetchProjects';
import useModal from '../../../../hooks/useModal';
import i18n from '../../../../i18n';
import { setIsLoading } from '../../../../reducers/loading';
import { setSelectedProject } from '../../../../reducers/onboarding';
import { reloadProjects } from '../../../../reducers/project';
import store from '../../../../store';
import modalStyles from '../../Components/containers/styles/ModalContainers.module.scss';
import SiteAccessButton from '../buttons/SiteAccessButton';
import OnboardingStart from '../modals/OnboardingStart';
import styles from '../modals/styles/PersonalOverview.module.scss';

interface PersonalOverviewContentProps {
    toggle?: () => void;
}

const PersonalOverviewContent: React.FC<PersonalOverviewContentProps> = (props) => {
  const [hasAccess, setHasAccess] = useState(false);
  const { closeModal, isModalOpen, openModal } = useModal();
  const { dismiss, enterAnimation, leaveAnimation, modal } = useCustomModalAnimations();
  const history = useHistory();
  const { getProjects } = useAcl();
  const fetchProjects = useFetchProjects();
  const submitting = useAppSelector(state => state.loading.isLoading.checkinOrOut);
  const projects: E2U.V1.Models.Project[] | undefined = getProjects();
  const { t } = useTranslation();
  const [presentAlert] = useIonAlert();

  const requestGeoLocationPermissions = (): Promise<Position | null> => {
    return new Promise((resolve) => {
      if ((isPlatform('android') || isPlatform('ios')) && !isPlatform('mobileweb')) {
        Geolocation.requestPermissions()
          .then((permission) => {
            if (permission.location === 'granted') {
              return Geolocation.getCurrentPosition();
            }
            return null;
          })
          .then(resolve)
          .catch(() => resolve(null));
      } else {
        if ('geolocation' in navigator) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              resolve({
                coords: {
                  latitude: position.coords.latitude,
                  longitude: position.coords.longitude,
                  accuracy: position.coords.accuracy,
                  altitude: position.coords.altitude,
                  altitudeAccuracy: position.coords.altitudeAccuracy,
                  heading: position.coords.heading,
                  speed: position.coords.speed,
                },
                timestamp: position.timestamp,
              });
            },
            () => resolve(null)
          );
        } else {
          resolve(null);
        }
      }
    });
  };

  const checkin = (project: E2U.V1.Models.Project) => {
    if (!project.has_site_access) {
      setHasAccess(!hasAccess);
      return;
    }
    store.dispatch(setIsLoading({ name: 'checkinOrOut', value: true }));
    requestGeoLocationPermissions()
      .then((location) => {
        const positionRequestBody = location
          ? {
            latitude: location.coords.latitude,
            longitude: location.coords.longitude,
            accuracy: location.coords.accuracy,
            altitude: location.coords.altitude,
            altitude_accuracy: location.coords.altitudeAccuracy,
            heading: location.coords.heading,
          }
          : {};

        return networking.post(`/api/v1/projects/${project.id}/${!project.checked_in ? 'checkin' : 'checkout'}`, {
          ...positionRequestBody,
        });
      })
      .then(() => {
        toasters.createToast({
          message: !project.checked_in
            ? t('Welcome to {project}', 'Welcome to {project}', { project: project.name })
            : t('You have checked out of {project}', 'You have checked out of {project}', { project: project.name }),
          background: 'var(--ion-color-light)',
        }, 'success');
      })
      .catch((error: E2U.V1.Response.Error) => {
        Sentry.captureException(error);
        toasters.createToast({
          message: error.message,
          background: 'var(--ion-color-light)',
        }, 'error');
      })
      .finally(() => {
        fetchProjects(true);
        store.dispatch(setIsLoading({ name: 'checkinOrOut', value: false }));
        store.dispatch(reloadProjects(true));
      });
  };

  const handleMapRedirect = (project: E2U.V1.Models.Project) => {
    store.dispatch(setSelectedProject(project));
    history.push(`/tools/${project.id}/worksites`);
    props.toggle && props.toggle();
  };

  useEffect(() => {
    if (!history.location.pathname.match(new RegExp(matchOnboarding))) {
      dismiss();
    }
  }, [history.location.pathname]);

  return (
    <>
      <IonContent
        className={classNames(styles['ion-padding'],
          styles['personaloverview-content-container']
        )}
      >
        {projects && projects.length === 0 && (
          <EmptyList
            title={i18n.t('No projects available')}
            message={i18n.t('Contact your supervisor to be added to a project or create one.')}
          />
        )}
        <IonGrid>
          <IonRow>
            <IonCol size="12">
              <IonList
                className={styles['project-checkin-container']}>
                {projects?.map((project, i) => {
                  return (
                    <div key={project.id} className='ion-margin-bottom'>
                      <IonLabel slot="stacked">{project.name}</IonLabel>
                      <IonItem color='none' lines='full'>
                        <IonButtons className='ion-no-margin'>
                          <div>
                            <BigUp.Buttons.Icon
                              onClick={() => {
                                if (project.has_site_access) {
                                  checkin(project);
                                } else {
                                  presentAlert({
                                    header: i18n.t('No access'),
                                    subHeader: i18n.t('A site access request is required'),
                                    message: i18n.t(
                                      'You currently don\'t have access to project {project}',
                                      'You currently don\'t have access to project {project}', { project: project.name }
                                    ),
                                    buttons: [
                                      i18n.t('Close')
                                    ]
                                  });
                                }
                              }}
                              color={!project.checked_in ? 'danger' : 'success'}
                              fill='solid'
                              disabled={submitting}
                              icon={{
                                icon: !project.checked_in ? arrowForward : arrowBack,
                                color: 'light',
                                size: 'large'
                              }}
                            />
                            <p className='ion-no-margin'>
                              {project.checked_in ? t('Check out') : i18n.t('Check in')}
                            </p>
                          </div>
                          <div>
                            <BigUp.Buttons.Icon
                              onClick={() => handleMapRedirect(project)}
                              color='none'
                              fill='clear'
                              icon={{
                                icon: locationOutline,
                                size: 'large'
                              }}
                            />
                            <p className='ion-no-margin'>{t('Map')}</p>
                          </div>
                          <div>
                            <SiteAccessButton project={project} onClick={() => {
                              store.dispatch(setSelectedProject(project));
                              history.push(`/site-access/details`);
                              props.toggle && props.toggle();
                            }}/>
                          </div>
                        </IonButtons>
                      </IonItem>
                    </div>
                  );
                })}
              </IonList>
            </IonCol>
          </IonRow>
        </IonGrid>

      </IonContent>

      <IonModal
        isOpen={isModalOpen}
        onIonModalDidDismiss={() => closeModal()}
        ref={modal}
        className={modalStyles['onboarding-start-modal']}
        enterAnimation={enterAnimation}
        leaveAnimation={leaveAnimation}
      >
        <OnboardingStart dismiss={dismiss}/>
      </IonModal>
    </>
  );
};

export default PersonalOverviewContent;
