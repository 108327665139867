import { IonIcon, IonLabel, IonTabButton } from '@ionic/react';
import { appsSharp, notificationsSharp, personCircleOutline, podiumSharp } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import BigUp from '../components/UI';
import { useAppSelector } from '../hooks';

const TabBar: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const project = useAppSelector(state => state.project.selectedProject);
  const checkProjectId = project?.id ? `/${project.id}` : '';
  const tabButtons = [
    { tab: `/tools`, activeIf: 'tools', icon: appsSharp, label: t('Home'), hasAccess: true },
    { tab: `/projects${checkProjectId}`, activeIf: 'projects', icon: podiumSharp, label: t('Projects'), hasAccess: true },
    { tab: `/notice-board${checkProjectId}`, activeIf: 'notice-board', icon: notificationsSharp, label: t('Notice Board'), hasAccess: true },
    { tab: `/profile${checkProjectId}`, activeIf: 'profile', icon: personCircleOutline, label: t('Profile'), hasAccess: true }
  ];

  const activeTab = (tab: string) => {
    return location.pathname.includes(tab) ? 'secondary' : 'medium';
  };

  return (
    <BigUp.Tabs.TabBar slot="bottom">
      {tabButtons.map((tab, index) => (
        tab.hasAccess && (
          <IonTabButton key={index} tab={tab.tab} href={tab.tab}>
            <IonIcon icon={tab.icon} size='large' color={activeTab(tab.activeIf)} />
            <IonLabel className='ion-no-margin' color={activeTab(tab.activeIf)}>{tab.label}</IonLabel>
          </IonTabButton>
        )
      ))}
    </BigUp.Tabs.TabBar>
  );
};

export default TabBar;
