import { IonPage, IonRouterOutlet } from '@ionic/react';
import { useTranslation } from 'react-i18next';

import { matchUuid } from '../../constants/matchUuid';
import { useAppSelector } from '../../hooks';
import AppLayout from '../../layout/AppLayout';
import ProjectForm from '../../pages/Projects/ProjectForm';
import ProjectList from '../../pages/Projects/ProjectList';
import { AbilitiesWatchedRoute } from '../AbilitiesWatchedRoute';

const ProjectsRouteGroup: React.FC = () => {
  const { t } = useTranslation();
  const isDesktop = useAppSelector(state => state.desktopView.isDesktop);
  return (
    <IonPage>
      <IonRouterOutlet>
        <AbilitiesWatchedRoute path={`/projects/:uuid(${matchUuid})`} exact >
          <AppLayout canGoBack={false} headerTitle={t('Projects')}>
            <ProjectList />
          </AppLayout>
        </AbilitiesWatchedRoute>
        <AbilitiesWatchedRoute path={`/projects/create-new`} exact >
          <AppLayout canGoBack={false} headerTitle={t('Create new project')}>
            <ProjectForm />
          </AppLayout>
        </AbilitiesWatchedRoute>
        <AbilitiesWatchedRoute path={'/projects'} exact>
          <AppLayout canGoBack={false} headerTitle={t('Projects')}>
            <ProjectList />
          </AppLayout>
        </AbilitiesWatchedRoute>
      </IonRouterOutlet>
    </IonPage>
  );
};

export default ProjectsRouteGroup;
