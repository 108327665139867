import { IonCol, IonGrid, IonRow, IonToolbar } from '@ionic/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import stepBtns from './StepButtons.module.scss';
import BigUp from '../UI';
import type { ColourVariables, IonicThemeColours } from '../UI/variables';

interface StepButtonTypes {
  leftTitle?: string;
  leftIonColor?: string;
  leftIcon?: string;
  leftClassName?: string;
  leftDisabled?: boolean;
  leftRouterLink?: string;
  leftOnClick?: () => void;
  rightTitle?: string;
  rightIonColor?: string;
  rightIcon?: string;
  rightClassName?: string;
  rightDisabled?: boolean | any;
  rightSubmit?: any;
  rightRouterLink?: string | undefined;
  rightButtonTextColour?: IonicThemeColours | ColourVariables;
  rightOnClick?: () => void | undefined;
}

const StepButtons: React.FC<StepButtonTypes> = (props) => {
  const { t } = useTranslation();
  return (
    <IonToolbar className={stepBtns['step-btns-container']}>
      <IonGrid>
        <IonRow className='ion-justify-content-between'>
          <IonCol size='auto' className="ion-text-center ion-align-items-center">
            {props.leftOnClick
              ? <BigUp.Buttons.Regular
                title={props.leftTitle || t('cancel')}
                expand='block'
                color={props.leftIonColor}
                className={props.leftClassName || stepBtns['left-step-btn']}
                disabled={props.leftDisabled || false}
                routerLink={props.leftRouterLink}
                onClick={() => props.leftOnClick!()}
                icon={{
                  icon: props.leftIcon,
                  size: 'large',
                }}
              />
              : <React.Fragment />
            }
          </IonCol>

          <IonCol size='auto' className="ion-text-center ion-align-items-center">
            <BigUp.Buttons.Regular
              expand='block'
              color={props.rightIonColor}
              className={props.rightClassName || stepBtns['right-step-btn']}
              type={props.rightSubmit}
              disabled={props.rightDisabled}
              routerLink={props.rightRouterLink}
              onClick={() => props.rightOnClick!() || undefined}
              style={{ color: props.rightButtonTextColour }}
              title={props.rightTitle || t('Next')}
              {...typeof props.rightIcon !== 'undefined' && {
                icon: {
                  icon: props.rightIcon,
                  size: 'large',
                  slot: 'end'
                },
              }}
            />
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonToolbar>
  );
};

export default StepButtons;
