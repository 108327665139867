import { IonCol, IonGrid, IonRow } from '@ionic/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import type PrecalculationsRadialChartProps from './PrecalculationsRadialChart';
import styles from './PrecalculationsRadialChart.module.scss';
import BigUp from '../../../../../components/UI';
import formatNumber from '../../../../../tools/formatNumber';

const PrecalculationsRadialChart: React.FC<PrecalculationsRadialChartProps> = ({
  pieChartData,
  remainingTotal,
  selectedPrecalculation,
  total
}) => {
  const { t } = useTranslation();
  const {
    precalculationId,
  } = useParams<{
    historicalPrecalculationId: string | undefined
    precalculationId: string | undefined,
  }>(); return (
    <>
      <IonGrid>
        <IonRow>
          <IonCol>
            <BigUp.Label.V2Thick label={t('Overview')} color='medium' />
          </IonCol>
        </IonRow>
      </IonGrid>
      <article className={styles.radialChartContainer}>
        <section className={styles.radialChartSection}>
          <div className={styles.chartWrapper}>
            <BigUp.Graphs.RadialChart data={
              precalculationId
                ? pieChartData.precalculation
                : pieChartData.historical
            } percentageIndex={1} size={'100%'} />
          </div>
          <div className={styles.indicatorWrapper}>
            <div className={styles.indicator}>
              <BigUp.Label.Indicator
                indicator={{
                  color: 'var(--bigup-gradient-primary)',
                  shape: 'circle',
                }}
                label={{
                  start: { label: t('Remaining') },
                  end: {
                    label: remainingTotal.precalculation.value === 0
                      ? '0'
                      : formatNumber(remainingTotal.precalculation.value).toString()
                  }
                }}
              />
            </div>
            <div className={styles.indicator}>
              <BigUp.Label.Indicator
                indicator={{
                  color: 'var(--bigup-gradient-secondary)',
                  shape: 'circle',
                }}
                label={{
                  start: { label: t('Secured') },
                  end: {
                    label: selectedPrecalculation?.secured_costs
                      ? formatNumber(selectedPrecalculation.secured_costs).toString()
                      : '0'
                  }
                }}
              />
            </div>
            <div className={styles.indicator}>
              <BigUp.Label.Indicator
                indicator={{
                  color: 'var(--ion-color-medium-shade)',
                  shape: 'circle',
                }}
                label={{
                  start: { label: t('Total') },
                  end: {
                    label: total === 0
                      ? '0'
                      : formatNumber(total).toString()
                  },
                }}
              />
            </div>
          </div>
        </section>
      </article>
    </>
  );
};

export default PrecalculationsRadialChart;
