import { IonCol, IonGrid, IonRow, useIonRouter, useIonViewWillEnter } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { checkmarkCircle } from 'ionicons/icons';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import Section from './Sections';
import Sections from './Sections/Sections';
import { networking } from '../../../api/networking';
import BigUp from '../../../components/UI';
import toasters from '../../../components/UI/Toasts';
import { useAppSelector } from '../../../hooks';
import useCameraUpload from '../../../hooks/useCameraUpload';
import useFileUpload from '../../../hooks/useFileUpload';
import AppLayout from '../../../layout/AppLayout';

export type WorkPreparationRows = {
  id: string;
  active: boolean;
  description: string;
  files: E2U.V1.Models.File[];
};
export interface WorkPreparation {
  activity: string;
  work_operation: string;
  description: string;
  rows: WorkPreparationRows[];
}

const defaultValues: WorkPreparation = {
  activity: '',
  work_operation: '',
  description: '',
  rows: [],
};

const PreparationForm: React.FC = () => {
  const router = useIonRouter();
  const methods = useForm<WorkPreparation>({ defaultValues });
  const { uuid } = useParams<{ uuid: string }>();
  const project = useAppSelector(state => state.project.selectedProject);
  const { t } = useTranslation();
  const cameraProps = useCameraUpload();
  const fileProps = useFileUpload();

  const onSubmit = (data: WorkPreparation) => {
    networking.post(`/api/v1/projects/${uuid || project?.id}/work_preparation_protocol`, data)
      .then((response) => {
        const finishedPromises: Promise<any>[] = [];
        (response.data.data.entries ?? []).forEach((entry: any) => {
          entry.reports.forEach((report: any) => {
            finishedPromises.push(
              Promise.allSettled([
                cameraProps.uploadSelectedPhotos('/api/v1/work_preparations/protocol_entry_reports', report.id || ''),
                fileProps.uploadSelectedFiles('/api/v1/work_preparations/protocol_entry_reports', report.id || ''),
              ])
            );
          });
        });

        router.push(`/tools/preparation/${response.data.data.id}`);
        toasters.createToast({
          message: t('Preparation created successfully'),
          icon: checkmarkCircle,
          iconColour: 'success',
          background: 'var(--ion-color-light)'
        }, 'success');

        return Promise.all(finishedPromises);
      })
      .then(() => {
        methods.reset();
      })
      .catch((error) => {
        toasters.createToast({
          message: error.response.data.error,
          iconColour: 'danger',
          background: 'var(--ion-color-light)'
        }, 'error');
      });
  };

  const getAllPreparationsForProject = () => {
    if (!project?.id) return;
    networking.get(`/api/v1/projects/${uuid || project?.id}/work_preparation_protocols`)
      .then(response => {
        console.log(response.data.data);
      });
  };

  useIonViewWillEnter(() => {
    getAllPreparationsForProject();
  }, []);

  const createSomething = () => {
    networking.post(`/api/v1/projects/${project?.id}/work_preparation_copy_node`)
      .then(response => response.data.data);
  };

  return (
    <AppLayout
      header={{ hasHeader: false }}
      headerTitle={t('Create a preparation')}
      tabs={false}
      scroll={true}
      contentColour='var(--ion-color-light-shade)'>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Section.Project />
          <Section.Description />
          <Sections cameraProps={cameraProps} fileProps={fileProps} />
          <IonGrid>
            <IonRow className='ion-justify-content-center'>
              <IonCol size={'6'}>
                <BigUp.Buttons.Regular
                  color={'primary'}
                  expand='block'
                  disabled={!project || !methods.formState.isValid}
                  size='default'
                  shape='round'
                  title={t('Sign')}
                  type='submit'
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        </form>
      </FormProvider>
    </AppLayout>
  );
};

export default PreparationForm;
