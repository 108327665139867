import { IonContent, IonLabel, IonSelect, IonSelectOption, IonToggle, useIonAlert } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { trash } from 'ionicons/icons';
import React, { useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useStore } from 'react-redux';

import { updateRowInputs } from './formProps';
import styles from './PrecalculationForm.module.scss';
import { networking } from '../../../../../api/networking';
import SectionLoader from '../../../../../components/SectionLoader/SectionLoader';
import toasters from '../../../../../components/Toasts/Toasts';
import { BigUp } from '../../../../../components/UI';
import { useAppSelector } from '../../../../../hooks';
import i18n from '../../../../../i18n';
import { setIsLoading } from '../../../../../reducers/loading';

interface EditProps {
  onDismiss(): void;
  onRowSave: () => void;
  deletePrecalculationRow: () => void;
  selectedRow: Partial<E2U.V1.Models.PrecalculationRow>;
}

const PrecalculationForm: React.FC<EditProps> = (props) => {
  const { t } = useTranslation();
  const [presentAlert] = useIonAlert();
  const [securedCost, setSecuredCost] = useState<boolean>(props.selectedRow.secured_cost ?? false);
  const [validationErrors, setValidationErrors] = useState<{ [field: string]: string[]; }>({});
  const [selectedActivityCodes, setSelectedActivityCodes] = useState<E2U.V1.Models.ActivityCode[]>([]);
  const store = useStore();
  const isLoading = useAppSelector(state => state.loading.isLoading.row);
  const precalculationRow: Partial<E2U.V1.Models.PrecalculationRow> = props.selectedRow;
  const formDefaults = {
    activity_code_id: props.selectedRow.activity_code?.id,
    cost_per_unit: props.selectedRow.cost_per_unit,
    created_at: props.selectedRow.created_at,
    id: props.selectedRow.id,
    precalculation_id: props.selectedRow.precalculation_id,
    quantity: props.selectedRow.quantity,
    secured_cost: props.selectedRow.secured_cost ?? false,
    unit: props.selectedRow.unit,
    updated_at: props.selectedRow.updated_at,
    name: props.selectedRow.name,
    description: props.selectedRow.description
  };
  const methods = useForm<E2U.V1.Models.PrecalculationRow>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    defaultValues: formDefaults
  });

  const isUpdating = Boolean(precalculationRow && precalculationRow.id);
  const submitToasters = isUpdating
    ? {
      pending: i18n.t('Saving precalculation'),
      success: i18n.t('Successfully saved precalculation'),
      error: i18n.t('Failed saving precalculation')
    }
    : {
      pending: i18n.t('Creating precalculation'),
      success: i18n.t('Successfully created precalculation'),
      error: i18n.t('Failed creating precalculation')
    };

  const handleSecuredCostToggle = () => {
    methods.setValue('secured_cost', !securedCost);
    setSecuredCost(!securedCost);
  };

  const handleSave: SubmitHandler<E2U.V1.Models.PrecalculationRow> = (data: E2U.V1.Models.PrecalculationRow) => {
    store.dispatch(setIsLoading({ name: 'precalculationRow', value: true }));

    const requestAttributes: E2U.V1.Models.PrecalculationRow[] = [];

    selectedActivityCodes.forEach(activityCode => {
      requestAttributes.push({
        ...data,
        activity_code_id: activityCode.id
      });
    });

    const saveRequest = isUpdating
      ? networking.put(`/api/v1/precalculation_rows/${precalculationRow?.id}`, data)
      : networking.post('/api/v1/precalculation_rows/bulk', requestAttributes);

    toasters
      .promise(saveRequest, submitToasters)
      .then(() => {
        props.onRowSave();

        if (!isUpdating) {
          methods.reset();
          setSelectedActivityCodes([]);
        }
      })
      .catch(
        (error: E2U.V1.Response.Error<E2U.V1.Models.PrecalculationRow>) => {
          if (networking.isAxiosError(error)) {
            if (
              error &&
              error.response?.data &&
              error.response.data.message &&
              error.response.data.message === 'Validation Failed'
            ) {
              setValidationErrors(error.response.data.data);
              return;
            }
          }
          Sentry.captureException(error);
        }
      )
      .finally(() => {
        store.dispatch(
          setIsLoading({ name: 'precalculationRow', value: false })
        );
      });
  };

  const items = updateRowInputs.map((input: any, i: number) => {
    let content;

    if (input.type === 'select') {
      content = (
        <IonSelect
          className={styles['quantity-select']}
          mode='ios'
          justify='space-between'
          color={'medium'}
          style={{
            color: 'var(--ion-color-medium)',
            fontSize: '0.9rem'
          }}
          label={input.label}
          labelPlacement='start'
          key={input.id}
          placeholder={'Select Option'}
          interface="popover"
          value={precalculationRow.unit}
          onIonChange={(e) => {
            methods.setValue(input.id, e.detail.value);
          }}
        >
          {input.options?.map((option: any) => (
            <IonSelectOption key={option.value} value={option.value}>
              {option.label}
            </IonSelectOption>
          ))}
        </IonSelect>
      );
    } else {
      content = (
        <BigUp.Input
          className='ion-no-padding'
          itemProps={{ className: 'ion-no-padding' }}
          style={{
            textAlign: 'end',
            '--color': 'var(--ion-color-medium)',
            fontSize: '0.9rem'
          }}
          key={input.id}
          {...input}
          lines={false}
          labelPlacement='start'
          type={input.type as any}
        />
      );
    }
    return {
      id: i,
      content
    };
  });
  return (
    <React.Fragment>
      <IonContent className={styles['precalculation-row-form-content']} scrollY={false}>
        {isLoading
          ? <SectionLoader />
          : (
            <React.Fragment>
              <FormProvider {...methods}>
                <form
                  onSubmit={methods.handleSubmit(handleSave)}
                >
                  <div className={styles['precalculation-row-form']}>

                    <div className={`ion-margin-top ${styles['precalculation-row-form-header']}`}>
                      <BigUp.Label.Regular className='ion-no-margin' color={'medium'} label={t(`${precalculationRow.stamped_activity_code} - ${precalculationRow.activity_code?.name}`)} />
                      <BigUp.Buttons.Icon
                        onClick={() =>
                          presentAlert({
                            header: t('Are you sure you want to delete this precalculation?'),
                            message: precalculationRow.activity_code_id && precalculationRow.name,
                            buttons: [
                              {
                                text: t('Cancel'),
                                role: 'cancel'
                              },
                              {
                                text: t('OK'),
                                role: 'confirm',
                                handler: () => {
                                  props.deletePrecalculationRow();
                                }
                              }
                            ]
                          })
                        }
                        icon={{
                          icon: trash,
                          color: 'medium',
                          size: 'large'
                        }}
                        fill='clear'
                        padding={10}
                      />
                    </div>
                    <div className={styles['input-list-container']}>
                      <BigUp.ItemList
                        items={items}
                        extraRow={{
                          hasExtraRow: true,
                          item: { detail: false, button: false },
                          content: (
                            <div className={styles['toggle-container']}>
                              <IonToggle
                                mode='ios'
                                name='secured_cost'
                                labelPlacement='start'
                                onClick={handleSecuredCostToggle}
                                checked={securedCost}
                              >
                                <IonLabel className='ion-no-margin' color='medium'>
                                  {t('Secured cost')}
                                </IonLabel>
                              </IonToggle>
                            </div>
                          )
                        }}
                      />
                    </div>
                    <div className='ion-margin-vertical'>

                      <BigUp.Buttons.Primary
                        title={t('Save')}
                        type='submit'
                      />
                    </div>
                  </div>
                </form>
              </FormProvider>
            </React.Fragment>

          )}

      </IonContent>
    </React.Fragment>
  );
};

export default PrecalculationForm;
