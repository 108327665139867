import { IonPage, IonRouterOutlet } from '@ionic/react';
import React from 'react';
import { Route } from 'react-router';

import { matchUuid } from '../../constants/matchUuid';
import AppLayout from '../../layout/AppLayout';
import TabBar from '../../layout/TabBar';
import ProjectReportsPage from '../../pages/ProjectReports';
import ProjectCreate from '../../pages/Projects/ProjectCreate/ProjectCreate';
import ObservationReport from '../../pages/Projects/ReportsPage/ObvervationReports/ObservationReport';
import IncidentReport from '../../pages/Reports/IncidentReport';
import ToolsPage from '../../pages/Tools';
import ToolList from '../../pages/Tools/ToolList';
import AssessmentList from '../../pages/WorkPreparation/AssessmentList';
import PreparationDetails from '../../pages/WorkPreparation/PreparationDetails';
import PreparationForm from '../../pages/WorkPreparation/PreparationForm';
import WorkPreparations from '../../pages/WorkPreparation/Preparations';

const ToolsRouteGroup: React.FC = () => {
  return (
    <IonPage>
      <IonRouterOutlet>
        <Route path={'/tools'} component={ToolList} exact={true} />
        <Route path={`/tools/create-project`} component={ProjectCreate} exact={true} />
        <Route path={`/tools/work-preparation`} component={PreparationForm} exact={true} />
        <Route path={`/tools/work-preparation/:uuid(${matchUuid})`} component={PreparationForm} exact={true} />
        <Route path={`/tools/preparation/:preparationId(${matchUuid})`}>
          <PreparationDetails />
        </Route>

        <Route path={`/tools/:uuid(${matchUuid})/reports/incidents/:incident_uuid(${matchUuid})`} exact>
          <AppLayout>
            <IncidentReport title={''} />
          </AppLayout>
        </Route>
        <Route exact path={`/tools/work-preparations/:uuid(${matchUuid})`}>
          <IonPage>
            <WorkPreparations />
            <TabBar />
          </IonPage>
        </Route>

        <Route exact path={`/tools/work-preparations`}>
          <IonPage>
            <WorkPreparations />
            <TabBar />
          </IonPage>
        </Route>

        <Route path={`/tools/work-preparation/assessments/*`}>
          <AssessmentList />
        </Route>

        <Route path={`/tools/:uuid(${matchUuid})/reports/observations/:observation_uuid(${matchUuid})`} exact>
          <AppLayout>
            <ObservationReport />
          </AppLayout>
        </Route>

        <Route path={`/tools/:uuid(${matchUuid})/reports`} exact>
          <AppLayout scroll>
            <ProjectReportsPage />
          </AppLayout>
        </Route>

        <Route path={`/tools/:uuid(${matchUuid})`} component={ToolsPage} />

        <Route path={'/tools/:uuid(new)'} exact>
          <AppLayout scroll content_id='create_project'>
            <ProjectCreate />
          </AppLayout>
        </Route>
      </IonRouterOutlet>
    </IonPage>
  );
};

export default ToolsRouteGroup;
