import {
  IonCol,
  IonGrid,
  IonRow,
} from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { networking } from '../../../../../api/networking';
import SkeletonTextThreeLines from '../../../../../components/SkeletonComponents/SkeletonTextThreeLines';
import toasters from '../../../../../components/Toasts/Toasts';
import BigUp from '../../../../../components/UI';
import CreateButton from '../../../../../components/WorkPreparation/Nodes/CreateButton';
import NodesList from '../../../../../components/WorkPreparation/Nodes/List';
import { useAppSelector } from '../../../../../hooks';
import { setIsLoading } from '../../../../../reducers/loading';
import store from '../../../../../store';

interface NodesSettingPageProps {
  type: 'projects' | 'clients';
}

const NodesSettingPage: React.FC<NodesSettingPageProps> = ({ type }) => {
  const { t } = useTranslation();
  const selectedProject = useAppSelector(state => state.project.selectedProject);
  const selectedProjectId = useMemo(() => selectedProject?.id, [selectedProject]);
  const { client_id } = useParams<{ client_id: string }>();
  const id = useMemo(() => type === 'projects' ? selectedProjectId : client_id, [
    selectedProjectId,
    type,
    client_id
  ]);
  const [nodes, setNodes] = useState<any[]>([]);
  const loadingNodes = useAppSelector(state => state.loading.isLoading.fetchingNodes);

  const fetchNodes = () => {
    if (!id) {
      return;
    }
    store.dispatch(setIsLoading({
      name: 'fetchingNodes',
      value: true
    }));
    networking.get(`/api/v1/${type}/${id}/nodes?limit=9999`)
      .then((res: E2U.V1.Response.Success<E2U.V1.Objects.PaginatedData<any>>) => {
        setNodes(res.data.data.records);
      })
      .catch(() => {
        toasters.error(t('Failed to load templates. Please try again later or contact our support.'));
      })
      .finally(() => {
        store.dispatch(setIsLoading({
          name: 'fetchingNodes',
          value: false
        }));
      });
  };

  useEffect(() => {
    fetchNodes();
  }, []);

  useEffect(() => {
    fetchNodes();
  }, [type, id]);

  return (
    <div className={'ion-padding'}>
      <IonGrid>
        <IonRow>
          <IonCol>
            <BigUp.Title
              label={t('Work preparation templates for {name}', {
                name: selectedProject?.name ?? ''
              })}
            />
          </IonCol>
        </IonRow>
        {(loadingNodes)
          ? (
            <IonRow>
              <IonCol>
                <SkeletonTextThreeLines />
              </IonCol>
            </IonRow>
          )
          : (
            <>
              <IonRow>
                <IonCol>
                  <NodesList
                    type={type}
                    onNodeChange={() => fetchNodes()}
                    nodes={nodes}
                  />
                </IonCol>
              </IonRow>
              <IonRow className={'ion-justify-content-end'}>
                <IonCol size={'auto'}>
                  <CreateButton
                    onNodeCreated={() => fetchNodes()}
                    type={type}
                    id={selectedProjectId ?? ''}
                  />
                </IonCol>
              </IonRow>
            </>
          )}
      </IonGrid>
    </div>
  );
};

export default NodesSettingPage;
