import { IonText } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { useEffect } from 'react';
import type { FieldValues, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { networking } from '../../../api/networking';
import RelatedMultiSelectEdit from '../../../components/Search/RelatedMultiSelect/Edit/RelatedMultiSelectEdit';
import { useAppSelector } from '../../../hooks';
import useAcl from '../../../hooks/useAcl';
import i18n from '../../../i18n';
import type { TeamSelectionProps } from '../interfaces';

const TeamSelection: React.FC<TeamSelectionProps> = ({ fetchAllUsers, fetchTeam, type, users }) => {
  const { team_id } = useParams<{ team_id: string }>();
  const { t } = useTranslation();
  const { getProjects } = useAcl();
  const team = useAppSelector(state => state.team.selectedTeam);
  const projects = getProjects();
  const isDesktop = useAppSelector(state => state.desktopView.isDesktop);

  const checkType = type === 'projects' ? projects : users;

  const selectionActions = async (itemId: string, method: 'POST' | 'DELETE') => {
    const endpoint = `/api/v1/teams/${team_id}/${type === 'projects' ? 'projects' : 'users'}/${itemId}`;
    await ({
      POST: networking.post,
      DELETE: networking.delete
    }[method])(endpoint, { team_id })
      .catch((error) => {
        Sentry.captureException(error);
      });
  };

  const handleSelection: SubmitHandler<FieldValues> = async (data) => {
    const existingIds = (type === 'projects'
      ? team?.projects.map((project: E2U.V1.Models.Project) => project.id)
      : team?.users.map((user: any) => user.id)) ?? [];

    const selectedIds = data.map((item: any) => item.id);
    const itemsToRemove = existingIds.filter(id => !selectedIds.includes(id));
    const itemsToAdd = data.filter((item: any) => !existingIds.includes(item.id));

    const removeActions = itemsToRemove.map(id => () => selectionActions(id, 'DELETE'));
    const addActions = itemsToAdd.map((item: { id: string; }) => () => selectionActions(item.id, 'POST'));

    await executeActions([...removeActions, ...addActions]);

    fetchTeam && fetchTeam();
  };

  const executeActions = async (actions: (() => Promise<void>)[]) => {
    await Promise.all(actions.map(action => action()));
  };

  useEffect(() => {
    if (type === 'users') {
      fetchAllUsers && fetchAllUsers();
    }
  }, []);

  const descriptionTitle = type === 'projects'
    ? t('Handle projects')
    : i18n.t('Handle users');

  return (
    <>
      {!isDesktop && (
        <p style={{ fontWeight: 600 }}>
          <IonText color={'dark'}>
            {descriptionTitle}
          </IonText>
        </p>
      )}

      <RelatedMultiSelectEdit
        {...isDesktop && {
          label: descriptionTitle,
          button: 'solid'
        }}
        onChange={() => {
        }}
        action='submit'
        postSelected={(data: any) => handleSelection(data)}
        records={checkType}
        model={type}
        modalTitle={i18n.t(type === 'projects' ? 'Select projects' : 'Select users')}
        value={type === 'projects' ? team?.projects : team?.users}
        {...(type === 'projects'
          ? { displayFields: ['name'] }
          : { displayFields: ['first_name', 'last_name'] })}
        hideSelected
      />
    </>
  );
};

export default TeamSelection;
