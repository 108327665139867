import { helpCircleSharp } from 'ionicons/icons';

import type { InputProps } from '../../../../../components/UI/Inputs/Input.interface';
import i18n from '../../../../../i18n';

export const updateRowInputs: InputProps<any>[] | any = [
  {
    id: 'name',
    name: 'name',
    label: i18n.t('Name'),
    className: 'ion-no-padding ion-no-margin',
    register: 'name',
    placeholder: i18n.t('Name'),
    type: 'text',
    inputMode: 'text' as const,
    validation: {
      required: {
        value: false
      }
    }
  },
  {
    id: 'unit',
    label: i18n.t('Unit'),
    type: 'select' as any,
    options: [
      { value: 'sqm', label: i18n.t('sqm') },
      { value: 'st', label: i18n.t('st') }
    ] as any,
    validation: {
      required: false
    }
  },
  {
    id: 'quantity',
    label: i18n.t('Quantity'),
    register: 'quantity',
    inputMode: 'numeric',
    placeholder: i18n.t('Quantity'),
    validation: {
      required: {
        value: false
      },
    },
  },
  {
    id: 'cost_per_unit',
    label: i18n.t('Cost per unit'),
    register: 'cost_per_unit',
    inputMode: 'numeric',
    placeholder: i18n.t('Cost per unit'),
    validation: {
      required: {
        value: false
      },
      min: {
        value: 0,
        message: i18n.t('Cost per unit can\'t be smaller than 0')
      }
    }
  },
  {
    id: 'description',
    name: 'description',
    label: i18n.t('Description'),
    register: 'description',
    placeholder: i18n.t('Add description to precalculation'),
    inputMode: 'text',
    validation: {
      required: {
        value: false
      }
    }
  }
];

export const precalculationRowInputs: (id: string) => InputProps<any>[] | any = (id) => [
  {
    icon: helpCircleSharp,
    id: 'name',
    name: 'name',
    label: i18n.t('Section'),
    className: 'ion-no-padding ion-no-margin',
    register: `rows.${id}.name`,
    placeholder: i18n.t('Name'),
    type: 'text',
    inputMode: 'text' as const,
    validation: {
      required: false
    }
  },
  {
    id: 'unit',
    label: i18n.t('Unit'),
    type: 'select' as any,
    options: [
      { value: 'sqm', label: i18n.t('sqm') },
      { value: 'st', label: i18n.t('st') }
    ] as any,
    validation: {
      required: false
    }
  },
  {
    id: 'quantity',
    label: i18n.t('Quantity'),
    register: `rows.${id}.quantity`,
    inputMode: 'numeric',
    type: 'number',
    placeholder: i18n.t('Quantity'),
    validation: {
      required: false
    },
  },
  {
    id: 'cost_per_unit',
    label: i18n.t('Cost per unit'),
    register: `rows.${id}.cost_per_unit`,
    inputMode: 'numeric',
    type: 'number',
    placeholder: i18n.t('Cost per unit'),
    validation: {
      required: false,
      min: {
        value: 0,
        message: i18n.t('Cost per unit can\'t be smaller than 0')
      }
    }
  },
  {
    id: 'description',
    name: 'description',
    label: i18n.t('Description'),
    register: `rows.${id}.description`,
    placeholder: i18n.t('Add description to precalculation'),
    inputMode: 'text',
    validation: {
      required: false
    }
  }
];
