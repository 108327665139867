import { IonCol, IonGrid, IonList, IonModal, IonRow, } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { pencil } from 'ionicons/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import styles from './ProjectInformation.module.scss';
import { networking } from '../../../../api/networking';
import type { LockedProject } from '../../../../components/Accordion/Accordion';
import AclCurtain from '../../../../components/AclCurtain';
import DesktopWrapper from '../../../../components/DesktopWrapper';
import SkeletonTextThreeLines from '../../../../components/SkeletonComponents/SkeletonTextThreeLines';
import toasters from '../../../../components/Toasts/Toasts';
import BigUp from '../../../../components/UI';
import ItemWithTotal from '../../../../components/UI/Items/components/ItemWithTotal';
import { useAppSelector } from '../../../../hooks';
import useAlert from '../../../../hooks/useAlert';
import useModal from '../../../../hooks/useModal';
import formatNumber from '../../../../tools/formatNumber';
import EditProject from '../../../Projects/EditProject';

const ProjectInformation: React.FC<LockedProject> = (project: LockedProject) => {
  const { uuid } = useParams<{ uuid: string }>();
  const { t } = useTranslation();
  const history = useHistory();
  const { alert } = useAlert();
  const modal = useModal();
  const selectedProject = useAppSelector((state) => state.project.selectedProject as any);
  const isLoading = useAppSelector((state) => state.loading.isLoading.selectedProject);

  const handleFormModal = () => {
    modal.toggleModal();
    if (modal.isModalOpen) {
      project.fetchProject();
    }
  };

  const deleteProject = () => {
    toasters.promise(
      networking.delete(`/api/v1/projects/${uuid}`),
      {
        success: t('Successfully deleted project.'),
        pending: t('Deleting project...'),
        error: t("Couldn't delete project")
      })
      .catch((error: E2U.V1.Response.Error) => {
        Sentry.captureException(error);
      })
      .finally(() => {
        handleFormModal();
        history.push('/');
      });
  };

  const confirmDelete = () => {
    alert(
      t(`Deleting project: ${selectedProject?.name}`),
      t(`Are you sure you want to delete project: ${selectedProject?.name}?`),
      () => deleteProject(),
      t('Cancel'),
      t('Delete'),
    );
  };

  return (
    <DesktopWrapper>
      {isLoading && <SkeletonTextThreeLines/>}
      <IonGrid style={{ width: '100%' }}>
        <IonRow>
          <IonCol>
            <BigUp.Title label={t('Project information')}/>
          </IonCol>
        </IonRow>
        <IonRow className='ion-align-items-center'>
          <IonCol className='ion-text-end'>
            <AclCurtain requiredAbilities={['can_create_projects']}>
              <BigUp.Buttons.Regular
                title={t('Edit')}
                color={'secondary'}
                onClick={() => handleFormModal()}
                icon={{ icon: pencil, slot: 'end' }}
              />
            </AclCurtain>
          </IonCol>
        </IonRow>
      </IonGrid>

      <IonGrid style={{ width: '100%' }}>
        <IonRow className={'ion-text-left'}>
          <IonList style={{ marginBottom: 100 }}>
            <ItemWithTotal
              labelLeft={t('Name')}
              labelRight=''
              descriptionLeft={selectedProject?.name as string}
              total=''
            />
            <ItemWithTotal
              labelLeft={t('Description')}
              labelRight={''}
              descriptionLeft={selectedProject?.description ?? t('No description has been added')}
              total={''}
            />
            <ItemWithTotal
              labelLeft={t('Category')}
              labelRight={''}
              descriptionLeft={selectedProject?.category?.name ?? t('No category has been added')}
              total={''}
            />
            <ItemWithTotal
              labelLeft=''
              labelRight={t('SEK excl vat')}
              descriptionLeft={t('Total cost/unit')}
              total={(typeof selectedProject !== 'undefined' && selectedProject?.production_budget?.total < 1000)
                ? selectedProject?.production_budget?.total
                : formatNumber(selectedProject?.production_budget?.total)
              }
            />
            <ItemWithTotal
              labelLeft=''
              labelRight={t('SEK/BOA excl vat')}
              descriptionLeft={t('Used area living')}
              total={(typeof selectedProject !== 'undefined' && selectedProject.living_area < 1000)
                ? selectedProject.living_area
                : formatNumber(selectedProject?.living_area)
              }
              amountValue={(typeof selectedProject !== 'undefined' && selectedProject.living_object_quantity)}
            />
            <ItemWithTotal
              labelLeft=''
              labelRight={t('SEK/LOA excl vat')}
              descriptionLeft={t('Other area')}
              total={(typeof selectedProject !== 'undefined' && selectedProject.other_area < 1000)
                ? selectedProject.other_area
                : formatNumber(selectedProject?.other_area)
              }
              amountValue={(typeof selectedProject !== 'undefined' && selectedProject.other_object_quantity)}
            />
            <ItemWithTotal
              labelLeft=''
              labelRight={t('BTA')}
              descriptionLeft={t('Total area')}
              total={(typeof selectedProject !== 'undefined' && selectedProject.total_area < 1000)
                ? selectedProject.total_area
                : formatNumber(selectedProject?.total_area)
              }
            />
          </IonList>
        </IonRow>
      </IonGrid>

      <IonModal isOpen={modal.isModalOpen} onIonModalDidDismiss={() => modal.closeModal()}>
        <EditProject
          inEditMode={true}
          onCancel={handleFormModal}
          onDelete={confirmDelete}
          onModalClose={modal.closeModal}
        />
      </IonModal>
    </DesktopWrapper>
  );
};
export default ProjectInformation;
