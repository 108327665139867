import { IonButton, IonCol, IonContent, IonGrid, IonInput, IonItem, IonLabel, IonModal, IonRow, IonTextarea } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import styles from './Tiles.module.scss';
import type { DeviationProps } from './TilesTypings';
import { networking } from '../../../../api/networking';
import toasters from '../../../../components/Toasts/Toasts';
import i18n from '../../../../i18n';
import { setActivityCodes } from '../../../../reducers/ActivityCodes';
import { setIsLoading } from '../../../../reducers/loading';
import store from '../../../../store';
import formatNumber from '../../../../tools/formatNumber';
import type { AmmendmentData } from '../../Subpanels/EconomyMonitoring/EconomyMonitoringRows/add/FormTypings';

const ChangesTile: React.FC<DeviationProps> = ({ clickable, fetchEconomyMonitoring, row }) => {
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [addChanges, setAddChanges] = useState<boolean>(false);

  const { t } = useTranslation();
  const history = useHistory();
  const { uuid } = useParams<{ uuid: string }>();
  const methods = useForm<AmmendmentData>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
  });

  const [validationErrors, setValidationErrors] = useState<{
    [field: string]: string[];
  }>({});

  const toggleAddChanges = () => {
    if (clickable === false) {
      return;
    }
    setAddChanges(!addChanges);
    fetchEconomyMonitoring!();
  };

  const postChangesAndDeviations: SubmitHandler<AmmendmentData> = (
    data: AmmendmentData
  ) => {
    toasters
      .promise(
        networking.post(
          `/api/v1/not_posteds/${row?.id}/change`,
          data
        ),
        {
          pending: i18n.t(`Trying to post changes`),
          success: i18n.t(`Sucessfully posted changes`),
          error: i18n.t('error')
        }
      )
      .then(() => {
        toggleAddChanges();
      })
      .catch((error: E2U.V1.Response.Error) => {
        if (networking.isAxiosError(error)) {
          if (
            error &&
            error.response?.data &&
            error.response.data.message &&
            error.response.data.message === t('Validation Failed')
          ) {
            setValidationErrors(error.response.data.data);
            return;
          }
        }
        Sentry.captureException(error);
      })
      .finally(() => {
        store.dispatch(
          setIsLoading({ name: 'deviationsAndChanges', value: false })
        );
      });
  };

  useEffect(() => {
    if (row && row.changes_total) {
      setHasChanges(true);
    } else {
      setHasChanges(false);
    }
  }, [row]);

  return (
    <React.Fragment>
      <div className={styles.tile}>
        <div className={classNames(styles.container, styles.isChanges)}>
          <IonLabel className={classNames(styles.title, (
            hasChanges
              ? styles['has-changes']
              : ''))}>
            {t('Changes')}
          </IonLabel>
          {(row) && <p
            className={classNames(styles.value)}
            style={{
              opacity: 0.7,
              width: '100%',
              textAlign: 'center',
              textTransform: 'uppercase',
              fontWeight: 800,
              borderRadius: '8px',
              cursor: 'pointer',
              ...(row.changes_total === 0)
                ? {
                  background: 'none ',
                  color: 'var(--ion-color-dark)',
                }
                : {
                  color: 'var(--ion-color-light)',
                  background: 'var(--ion-color-primary)',
                }
            }}
            {...clickable === true && {
              onClick: () => {
                store.dispatch(setActivityCodes([row.activity_code]));
                history.push(`/financials/${uuid}/changes/create`);
              }
            }}
          >
            {
              row.changes_total === 0
                ? clickable
                  ? '+ ' + t('add')
                  : row.changes_total
                : formatNumber(row.changes_total)
            }
          </p>}
        </div>
      </div>

      <IonModal
        className={styles['add-change-or-deviation-modal']}
        isOpen={addChanges}
        onDidDismiss={() => {
          setAddChanges(false);
        }}
      >
        <IonContent className='ion-padding'>
          <h1>{t('Changes')}</h1>
          <form onSubmit={methods.handleSubmit(postChangesAndDeviations)}>
            <IonGrid >
              <IonRow className='ion-justify-items-start ion-align-items-start'>
                <IonCol size='12' className='ion-margin-top'>
                  <IonItem className='ion-no-padding'>
                    <IonLabel position="stacked" className='ion-no-margin'>
                      {t('Quantity')}:
                    </IonLabel>
                    <IonInput
                      {
                        ...methods.register('quantity', {
                          required: true
                        })
                      }
                      type='number'
                      inputmode='numeric'
                      placeholder="Enter quantity" />
                  </IonItem>
                </IonCol>
                <IonCol size='12' className='ion-margin-top'>
                  <IonItem className='ion-no-padding'>
                    <IonLabel position="stacked" className='ion-no-margin'>
                      {t('Unit')}:
                    </IonLabel>
                    <IonInput
                      {
                        ...methods.register('unit', {
                          required: true
                        })
                      }
                      type='text'
                      placeholder="Enter unit" />
                  </IonItem>
                </IonCol>
                <IonCol size='12' className='ion-margin-top'>
                  <IonItem className='ion-no-padding' counter>
                    <IonLabel position="stacked" className='ion-no-margin'>
                      {t('Cost per unit')}:
                    </IonLabel>
                    <IonTextarea
                      {
                        ...methods.register('cost_per_unit', {
                          required: true
                        })
                      }
                      autoGrow
                      placeholder={i18n.t('cost per unit')}
                      maxlength={140} />
                  </IonItem>
                </IonCol>
              </IonRow>
            </IonGrid>
            <IonGrid className='ion-margin-bottom'>
              <IonRow className='ion-justify-content-center ion-align-items-center ion-margin-top'>
                <IonCol size='6' className='ion-text-center'>
                  <IonButton color={'medium'} onClick={() => toggleAddChanges()}>
                    {t('cancel')}
                  </IonButton>
                </IonCol>
                <IonCol size='6' className='ion-text-center'>
                  <IonButton
                    type='submit'
                    disabled={
                      !methods.formState.isDirty &&
                      !methods.formState.isValid
                    }
                    onClick={() => {
                      postChangesAndDeviations(methods.getValues());
                    }}>
                    {t('submit')}
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </form>
        </IonContent>
      </IonModal>

    </React.Fragment>
  );
};

export default ChangesTile;
