import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { useMemo } from 'react';

import { networking } from '../api/networking';
import { useAppSelector } from '../hooks';
import { setAbilities } from '../reducers/authentication';
import { setIsLoading } from '../reducers/loading';
import type { AclAbility } from '../routes/ProtectedRoute';
import store from '../store';

export interface AclScopeInterface {
    uuid: string;
    type: 'project' | 'client';
}

const useAcl = () => {
  const user = useAppSelector(state => state.authentication.user);
  const project = useAppSelector(state => state.project.selectedProject);
  const abilities = useAppSelector((state) => state.authentication.abilities);

  const getClient = () => {
    if (user?.clients?.length) {
      return user?.clients[0] satisfies E2U.V1.Models.Client;
    }

    return null;
  };

  const getProjects: () => E2U.V1.Models.Project[] = () => {
    return user?.projects ?? [];
  };

  const clientAbilities = useMemo(() => {
    const client = getClient();

    if (client) {
      return (abilities.client[client.id ?? ''] ?? []).map(a => a.name);
    }

    return [];
  }, [abilities]);

  const scopedAbilities = useMemo(() => {
    const client = getClient();

    if (!project) {
      return (abilities.client[client?.id ?? ''] ?? []).map(a => a.name);
    }

    return (abilities.project[project?.id ?? ''] ?? []).map(a => a.name);
  }, [abilities, project]);

  const fetchPermissions = (props: { scope: AclScopeInterface }) => {
    const { scope } = props;
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.append(scope.type + '_id', scope.uuid);

    store.dispatch(setIsLoading({ name: 'abilities', value: true }));
    networking.get(`/api/v1/me/acl/list/abilities?${urlSearchParams.toString()}`)
      .then((result: E2U.V1.Response.Success<AclAbility[]>) => {
        store.dispatch(setAbilities({
          id: scope.uuid,
          type: scope.type,
          abilities: result.data.data
        }));
      })
      .catch((error) => {
        Sentry.captureException(error);
        store.dispatch(setAbilities({
          id: scope.uuid,
          type: scope.type,
          abilities: []
        }));
      })
      .finally(() => {
        store.dispatch(setIsLoading({ name: 'abilities', value: false }));
      });
  };

  return {
    project,
    abilities,
    scopedAbilities,
    clientAbilities,
    fetchPermissions,
    getClient,
    getProjects,
  };
};

export default useAcl;
