import { IonLoading, useIonRouter } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import type { CancelTokenSource } from 'axios';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useStore } from 'react-redux';
import { matchPath } from 'react-router';
import type { RouteProps } from 'react-router-dom';

import { networking, setAxiosAccessToken } from './api/networking';
import { useAppSelector } from './hooks';
import { setProfilePicture, setShouldRedirect, setUser } from './reducers/authentication';
import { setIsLoading } from './reducers/loading';
import storage from './storage';

type ProtectedRouteProps = RouteProps

const AuthenticatedListener: React.FC<ProtectedRouteProps> = () => {
  const store = useStore();
  const router = useIonRouter();
  const isLoadingUser = useAppSelector(state => state.loading.isLoading.user);
  const { pathname } = router.routeInfo;
  const [cancelToken, setCancelToken] = useState<CancelTokenSource | null>(null);

  const handlePathMatching = () => {
    return (
      !matchPath(location.pathname, '/login') &&
      !matchPath(location.pathname, '/reset') &&
      !matchPath(location.pathname, '/join') &&
      !matchPath(location.pathname, '/registration') &&
      !matchPath(location.pathname, '/support')
    );
  };

  const fetchUser = () => {
    store.dispatch(setIsLoading({ name: 'user', value: true }));
    storage.get('access_token')
      .then((access_token: string) => {
        if (access_token) {
          if (cancelToken) {
            cancelToken.cancel();
          }
          const source = axios.CancelToken.source();
          setCancelToken(source);
          networking.get('/api/v1/me', {
            cancelToken: source.token,
          })
            .then((result: E2U.V1.Response.Success<E2U.V1.Models.User>) => {
              store.dispatch(setUser(result.data.data));
              updateProfilePicture(result.data.data);
              if (matchPath(location.pathname, '/login')) {
                router.push('/');
              }
            })
            .catch((error) => {
              Sentry.captureException(error);
              if (handlePathMatching()) {
                store.dispatch(setUser(null));
              }
            })
            .finally(() => {
              store.dispatch(setIsLoading({ name: 'user', value: false }));
            });
        } else {
          handleLoggedOutUser();
          store.dispatch(setIsLoading({ name: 'user', value: false }));
        }
      })
      .catch(() => {
        store.dispatch(setIsLoading({ name: 'user', value: false }));
        handleLoggedOutUser();
      });
  };

  const handleLoggedOutUser = () => {
    store.dispatch(setIsLoading({ name: 'user', value: false }));
    if (
      handlePathMatching()
    ) {
      store.dispatch(setShouldRedirect(true));
    }
  };

  const updateProfilePicture = (user: E2U.V1.Models.User) => {
    if (user.profile_picture_url) {
      axios.get(`${user.profile_picture_url}?base64=true`, {
        headers: {
          Accept: '*/*'
        }
      })
        .then((res) => {
          store.dispatch(setProfilePicture(`data:${res.headers['content-type']};base64,${res.data}`));
        });
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    fetchUser();
  }, [pathname]);

  return (<>
    <IonLoading isOpen={isLoadingUser} />
  </>);
};

export default AuthenticatedListener;
