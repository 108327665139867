import { IonCol, IonGrid, IonIcon, IonLabel, IonRow, useIonActionSheet, useIonPopover, useIonRouter } from '@ionic/react';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './ToolList.module.scss';
import { BigUp } from '../../../components/UI';
import type { SubmenuItem } from '../../../components/UI/SideMenu/V2/MenuTypings';
import SideMenuV2Layout from '../../../components/UI/SideMenu/V2/SideMenuV2Layout';
import { toolsSubmenuItems } from '../../../components/UI/SideMenu/V2/Submenus/ToolsSubmenu';
import { useAppSelector } from '../../../hooks';
import useAcl from '../../../hooks/useAcl';
import useFetchProjects from '../../../hooks/useFetchProjects';
import { setSelectedProject } from '../../../reducers/project';
import store from '../../../store';

interface ListPageProps {
  title?: string | undefined;
  description: string;
}

const ToolList: React.FC<ListPageProps> = () => {
  const { t } = useTranslation();
  const router = useIonRouter();
  const fetchProjects = useFetchProjects();
  const selectedProject = useAppSelector(state => state.project.selectedProject);
  const selectedProjectId = useMemo(() => selectedProject?.id, [selectedProject]);
  const projects = useAppSelector(state => state.project.projects);
  const isDesktop = useAppSelector(state => state.desktopView.isDesktop);
  const [nextPath, setNextPath] = useState<string | undefined>(undefined);
  const [present] = useIonPopover(BigUp.Popovers.ProjectSelectionPopover, {
    nextPath,
    onNavigate: (to: string) => {
      router.push(to);
      setNextPath(undefined);
    },
  });
  const [presentActionSheet] = useIonActionSheet();

  const homeScreenNavigation: SubmenuItem[] = useMemo(() => toolsSubmenuItems.map(
    item => ({
      ...item,
      route: item.route.replace('/:uuid?', `/${selectedProjectId ?? ''}`),
      base_route: item.route,
    })
  ), [selectedProjectId]);

  useEffect(() => {
    fetchProjects(true);
  }, []);

  const handleToolSelected = (e: Event, item: SubmenuItem) => {
    switch (true) {
      case !selectedProjectId:
        setNextPath(item.base_route);
        if (isDesktop) {
          present({
            event: e,
            dismissOnSelect: true,
          });
        } else {
          presentActionSheetHandler(e, item);
        }
        break;
      default:
        router.push(selectedProject ? item.route : '/tools');
        break;
    }
  };

  const presentActionSheetHandler = (e: Event, item: SubmenuItem) => {
    presentActionSheet({
      header: t('Select project'),
      buttons: (projects ?? []).map((project, i) => ({
        text: project.name ?? '',
        handler: () => {
          store.dispatch(setSelectedProject(project));
          router.push((item.base_route ?? item.route).replace(/:uuid\??/, project.id ?? ''));
        }
      }))
    });
  };

  return (
    <SideMenuV2Layout>
      <IonGrid className='ion-padding-top ion-margin-top'>
        <IonRow className='ion-justify-content-start'>
          {homeScreenNavigation.map((item, index) =>
            (
              <IonCol size={'3'} sizeLg={'2'} key={index} className='ion-margin-top'>
                <div className={styles.widgetContainer}>
                  <BigUp.Widget.ButtonContainer
                    onClick={(e: Event) => handleToolSelected(e, item)}
                  >
                    <IonIcon icon={item.icon} style={{ fontSize: '36px' }}/>
                  </BigUp.Widget.ButtonContainer>
                  <IonLabel className='ion-no-margin' color={'medium'}>
                    {item.title}
                  </IonLabel>
                </div>
              </IonCol>
            )
          )}
        </IonRow>
      </IonGrid>
    </SideMenuV2Layout>
  );
};

export default ToolList;
