import {
  IonCol,
  IonIcon,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding, IonLabel,
  IonList,
  IonLoading,
  IonRow
} from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import classNames from 'classnames';
import { cloudUpload } from 'ionicons/icons';
import React from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { networking } from '../../../api/networking';
import { useAppSelector } from '../../../hooks';
import i18n from '../../../i18n';
import FileSelectionButton from '../../../pages/Tools/ControlOfExecution/AddFilesModal/FileSelectionButton';
import styles from '../../../pages/Tools/SiteAccessRequests/Settings/Settings.module.scss';
import { setIsLoading } from '../../../reducers/loading';
import store from '../../../store';
import toasters from '../../Toasts/Toasts';
import BigUp from '../../UI';
import DragAndDrop from '../../UI/DragAnddrop/DragAndDrop';

interface MassAccessFormProps {
  onContentProcessed?: (recipients: E2U.V1.Objects.AclSearchResult) => void;
}

interface MassAccessFormData {
  recipients: string;
  delimiter: 'newline' | 'comma' | 'semicolon';
  file: File | undefined;
}

const MassAccessForm: React.FC<MassAccessFormProps> = (props) => {
  const { t } = useTranslation();
  const methods = useForm<MassAccessFormData>({
    defaultValues: {
      recipients: '',
      delimiter: 'newline',
      file: undefined
    }
  });
  const delimiter = methods.watch('delimiter');
  const isProcessingData = useAppSelector((state) => state.loading.isLoading.isProcessingData);
  const file = methods.watch('file');

  const giveAccess: SubmitHandler<MassAccessFormData> = (data) => {
    store.dispatch(setIsLoading({
      name: 'isProcessingData',
      value: true
    }));
    networking.post(`/api/v1/acl/search_bulk`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then((res: E2U.V1.Response.Success<E2U.V1.Objects.AclSearchResult>) => {
        toasters.success(t('Data processed. Please review the results and proceed.'));
        if (props.onContentProcessed) {
          props.onContentProcessed(res.data.data);
        }
      })
      .catch((error) => {
        toasters.error(error.message);
      })
      .finally(() => {
        store.dispatch(setIsLoading({
          name: 'isProcessingData',
          value: false
        }));
      });
  };

  return (
    <>
      {isProcessingData
        ? <IonLoading isOpen={true} />
        : <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(giveAccess)}>
            <IonRow>
              <IonCol>
                <BigUp.Label.Thick label={t('Paste team codes and/or user emails')} />
                <BigUp.Textarea
                  itemProps={{
                    className: 'ion-no-padding',
                    lines: 'none'
                  }}
                  label={t('Recipients')}
                  placeholder={t('Enter user emails and/or team codes')}
                  register={'recipients'}
                  validation={undefined}
                  rows={8}
                  helperText={t('Separate multiple recipients with a comma, semicolon or newline. Please remove eventual header columns prior.')}
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <BigUp.Label.Thick label={t('Or upload a .csv-file containing the teams and users')} />
              </IonCol>
            </IonRow>
            {(file)
              ? <IonRow>
                <IonCol>
                  <IonList>
                    <IonItemSliding>
                      <IonItem className={'ion-no-padding'}>
                        <IonLabel>
                          {file.name}
                        </IonLabel>
                      </IonItem>
                      <IonItemOptions>
                        <IonItemOption
                          onClick={() => methods.setValue('file', undefined)}
                          color={'danger'}
                        >
                          {t('Remove')}
                        </IonItemOption>
                      </IonItemOptions>
                    </IonItemSliding>
                  </IonList>
                </IonCol>
              </IonRow>
              : <IonRow>
                <IonCol>
                  <DragAndDrop
                    onFilesSelect={(files: File[]) => methods.setValue('file', files[0])}
                    acceptedFileTypes={{
                      'text/csv': ['.csv'],
                      'text/plain': ['.txt']
                    }}
                  >
                    <div className={classNames(styles['file-upload-container'], 'ion-margin-top')}>
                      <IonIcon
                        icon={cloudUpload}
                        style={{ fontSize: 40 }}
                        color='medium'
                      />
                      <BigUp.Label.Regular
                        label={i18n.t('Drop your files here to upload')}
                        color={'medium'} />
                      <BigUp.Label.Thick
                        className='ion-no-margin'
                        label={i18n.t('or')}
                        color={'medium'} />
                      <FileSelectionButton
                        label={[].length === 0
                          ? i18n.t('Select files')
                          : i18n.t('Add more files')
                        }
                        custom={false}
                        expand='block'
                        responsiveButton={false}
                        onFilesSelect={(files: File[]) => methods.setValue('file', files[0])}
                      />
                    </div>
                  </DragAndDrop>
                </IonCol>
              </IonRow>}
            <IonRow>
              <IonCol>
                <BigUp.Label.Thick label={t('Select the correct delimiter')} />
                <BigUp.Select
                  itemProps={{
                    className: 'ion-no-padding ion-margin-bottom'
                  }}
                  register={'delimiter'}
                  label={t('delimiter')}
                  data={[
                    { value: t('Newline'), id: 'newline' },
                    { value: t('Comma'), id: 'comma' },
                    { value: t('Semicolon'), id: 'semicolon' },
                  ]}
                  placeholder={t('Select a delimiter')}
                  handleSelection={(value) => methods.setValue('delimiter', value as MassAccessFormData['delimiter'])}
                  selected={delimiter}
                  validation={{
                    required: t('delimiter is required'),
                  }}
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <BigUp.Buttons.Regular
                  type={'submit'}
                  title={t('Process')}
                />
              </IonCol>
            </IonRow>
          </form>
        </FormProvider>}
    </>
  );
};

export default MassAccessForm;
